/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Box from "@material-ui/core/Box";
import { Paper, Button, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { updateLoginInfoAction, requestPasswordResetAction } from '../../state/loginActions';
import { ResetButton } from './LoginResetButton';

const useStyles = makeStyles((theme) => ({
  ResetButton: theme.overrides.ResetButton,
}));

export const PasswordResetEmailEntryForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.loginReducer.login.email)

  const handleEmailChange = (event) => {
    updateLoginInfoAction(dispatch,'email',event.target.value);
  };
  const submitPasswordResetEmail = async () => {
    requestPasswordResetAction(dispatch);  
  }

    return (
    <Paper style={{maxHeight:'100%'}}>
      <Typography variant='h2'>
        ENTER YOUR EMAIL
      </Typography>
      <Box>
        <TextField
          required
          label="Email"
          placeholder="email@example.com"
          variant="outlined"
          margin="normal"
          onChange={handleEmailChange}
          value={email}/>
      </Box>
      <Box>
        <Button 
        variant="contained" 
        color="primary" 
        onClick={submitPasswordResetEmail}>
          Submit
        </Button>
      </Box>
      <ResetButton buttonClass={classes.ResetButton}/>
    </Paper>
    )
}