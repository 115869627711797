/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import { resetLoginAction } from '../../state/loginActions';
import PropTypes from 'prop-types';

export const ResetButton = ({
    buttonClass
}) => {
    const dispatch = useDispatch();

    return  (
        <IconButton
        onClick={()=>resetLoginAction(dispatch)}
        className={buttonClass}>
            <ArrowBackIcon/>
        </IconButton>)
}
ResetButton.propTypes = {
    buttonClass: PropTypes.string,
}