/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/



import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { Auth } from 'aws-amplify';
import Layout from '../../layout/Layout';
import { routes } from '../../../routes';
import { checkUserAction } from '../../state/user/userActions';
import refreshToken from '../../utils/functions/auth/refreshToken'

let timeoutIds = [];
const refreshPeriod = 1000*60*59; // 59 minutes

const clearTimeouts = () => {
  timeoutIds.forEach(clearTimeout);
  timeoutIds = [];
}

export const AuthComponent = () => {
  
  const loginSuccessfulInSaga = useSelector(state => state.loginReducer.login.loginSuccessful);
  const user = useSelector(state => state.userReducer.user);
  // one time start-up check in case user is already/still authenticated
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = React.useState(false);
  
  useEffect(() => {
      checkUserAction(dispatch,setAuthenticated);
  }, [dispatch, loginSuccessfulInSaga]);


  // set up Timeout that periodically refreshes accessTokens
  useEffect(() => {

    // If user is present, get tokens, else clear all the timeouts
    if (user) {

      // Function that calls itself with a timeout to refresh accessTokens
      const tokenRefreshLoop = () => setTimeout(() => {
        refreshToken(dispatch);
        // Record timeout id for clearing later
        const timeoutId = setTimeout(tokenRefreshLoop,refreshPeriod);
        timeoutIds.push(timeoutId);
      })
  
      // Check number of timeout ids, don't want to register multiple loops
      if (timeoutIds.length === 0) {
        const timeout = setTimeout(tokenRefreshLoop,refreshPeriod)
        timeoutIds.push(timeout);
        // Initial token setting
        refreshToken(dispatch);
      }

    } else {
      clearTimeouts();
    }

    return () => {
      clearTimeouts();
    }
  }, [dispatch,user])

  return (
    <Layout 
    authenticated={authenticated}
    routes={routes} />
  )
}