/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as Actions from './userActionTypes';

export const checkUserAction = (dispatch,componentStateSetter) => {
    dispatch({
    type: Actions.CHECK_USER,
    componentStateSetter: componentStateSetter})};

export const signOutAction = (dispatch) => {
    dispatch({type: Actions.USER_SIGN_OUT});
};
export const refreshTokenAction = (dispatch, userSession) => {
    dispatch({type: Actions.USER_REFRESH_TOKEN,
    userSession: userSession});
};