/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { takeEvery, put, all, call } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import * as Actions from './userActionTypes';
import * as loginActions from '../../../views/LoginPage/state/loginActionTypes';

function checkUserCall() {
    //return true;
    return Auth.currentAuthenticatedUser();
}

function signOutCall() {
  return Auth.signOut();
}

export function* checkUserSaga() {
    yield takeEvery(Actions.CHECK_USER,checkCurrentUser);
}
function* checkCurrentUser(action) {
    try {
      const user = yield call(checkUserCall);
      yield put({ type: Actions.CHECK_USER_SUCCEEDED, user:user });
      if (action.componentStateSetter) {
        action.componentStateSetter(true);
      }
    }
    catch (error) {
      console.log('error occurred in saga: ',error)
      yield put({ type: Actions.CHECK_USER_FAILED, error: error })
      if (action.componentStateSetter) {
        action.componentStateSetter(false);
      }
    }
}


export function* signOutUserSaga() {
  yield takeEvery(Actions.USER_SIGN_OUT,signOutUser);
}
function* signOutUser() {
  try {
    yield call(signOutCall);
    yield put({type:loginActions.RESET_LOGIN_STATE});
  }
  catch (error) {
    console.log('error occurred in saga: ',error)
  }
}

export default function* userSagas() {
    yield all([
      checkUserSaga(),
      signOutUserSaga(),
    ]);
  }