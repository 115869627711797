/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from "react";
import { List } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { DrawerSearch } from "./DrawerSearch";
import {  DrawerFilters } from "./DrawerFilters";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { ErrorAlert } from '../../../../common/components/feedback/ErrorAlert';


const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: "16px",
    overflow: "visible",
    width: theme.spacing(4),
    height: theme.spacing(4),
    "&:before": {
      fontSize: theme.spacing(4)
    }
  },
  searchHeader: {
    marginLeft:"16px",
  }
}));

export const DrawerContents = ({ handleOpen, expanded }) => {
  const classes = useStyles();

  return (
    <List component='nav'>
      <DrawerFilters handleOpen={handleOpen} style={classes.icon} expanded={expanded}/>
      {expanded && <Typography className={classes.searchHeader} variant='h5' >Search</Typography>}
      <DrawerSearch handleOpen={handleOpen} style={classes.icon} expanded={expanded}/>
      <ErrorAlert
      selector={(state) => state.analyticsReducer.search.error} />
      <ErrorAlert
      selector={(state) => state.analyticsReducer.detailsErrorMessage} />
      <ErrorAlert
      selector={(state) => state.analyticsReducer.neighborsErrorMessage} />
    </List>
  )
}
DrawerContents.propTypes = {
  handleOpen: PropTypes.func,
  expanded: PropTypes.bool,
}
