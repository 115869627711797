/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

export const CHECK_USER_SUCCEEDED = 'CHECK_USER_SUCCEEDED';
export const CHECK_USER_FAILED = 'CHECK_USER_FAILED';
export const CHECK_USER = "CHECK_USER";
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_REFRESH_TOKEN = "REFRESH_TOKEN"