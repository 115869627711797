/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


export const databaseMapping = {
    'Bank ID': {
        displayName: 'Bank ID',
        dbName: 'accuity_location_id',
    },
    'Branch Name': {
        displayName: 'Branch Name',
        dbName: 'branch_name',
    },
    'City': {
        displayName: 'City',
        dbName: 'city',
    },
    'Contact Info': {
        displayName: 'Contact Info' ,
        dbName: 'contact_information' ,
    },
    'Country': {
        displayName: 'Country',
        dbName: 'country',
    },
    'Institution Name': {
        displayName: 'Institution Name' ,
        dbName: 'institution_name_full',
    },
    'Officer Names': {
        displayName: 'Officer Names' ,
        dbName: 'officer_names',
    },
    'Physical Address 1': {
        displayName: 'Physical Address 1',
        dbName: 'physical_address_1',
    },
    'Physical Address 2': {
        displayName: 'Physical Address 2',
        dbName: 'physical_address_2' ,
    },
    'Postal Code': {
        displayName: 'Postal Code',
        dbName: 'postal_code' ,
    },
    'EU Sanctions': {
        displayName: 'EU Sanctions',
        dbName: 'sanctions_eu' ,
    },
    'HMT Sanctions': {
        displayName: 'HMT Sanctions',
        dbName: 'sanctions_hmt',
    },
    'OFAC Sanctions': {
        displayName: 'OFAC Sanctions' ,
        dbName: 'sanctions_ofac',
    },
    'OFAC Sanctions Info': {
        displayName: 'OFAC Sanctions Info',
        dbName: 'sanctions_ofac_info',
    },
    'UN Sanctions': {
        displayName: 'UN Sanctions',
        dbName: 'sanctions_un',
    },
    'State/Province': {
        displayName: 'State/Province',
        dbName: 'state_province_region_full',
    },
    'SWIFT/BIC': {
        displayName: 'SWIFT/BIC',
        dbName: 'swift_bic',
    },
    'Connections': {
        displayName: 'Connections' ,
        dbName: 'degree' ,
    },
    'FATF Rating': {
        displayName: 'Expand FATF Country Ratings',
        dbName: 'fatf_country_rating'
    },
    'OFAC Sanctions Additional': {
        displayName: 'Expand OFAC Sanctions Information',
        dbName: 'sanctions_ofac_info'
    },

}