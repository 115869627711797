/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import ClassificationBanner from './ClassificationBanner';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  appContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    height: "100%",
    overflow: "hidden",
  },
  routeContent: {
    top: theme.overrides.ClassificationBanner.height,
    position: 'relative',
    height: `calc(100% - ${2*theme.overrides.ClassificationBanner.height}px)`
  },
}))
const Layout = ({
  routes,
  authenticated,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.appContent}>
      <ClassificationBanner footer={false} />
        <div className={classes.routeContent}>
          <Switch>
          {routes.map((route) => {
            return route.privileged === authenticated
            ? <Route
              key={`route-${route.name}`}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => {
                return <route.component 
                  path={route.path} 
                  {...props} 
                  {...route.componentProps} />}} 
                  />
            : null})}
            {authenticated 
            ? <Redirect from='/' to='/analytics' />
            : <Redirect from='/' to='/login' />}
          </Switch>
        </div>
      <ClassificationBanner footer={true}/>
  </div>)
}
Layout.propTypes = {
  routes: PropTypes.array,
  authenticated: PropTypes.bool,
}

export default Layout;
