/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as Actions from './analyticsActionTypes';

/////////////////////////
// BANK DETAILS DRAWER
/////////////////////////
export const toggleBankDetailsDrawerAction = (dispatch) => {
    dispatch({type: Actions.TOGGLE_BANK_DETAILS_DRAWER});
  };
export const retrieveBankDetailsAction = (dispatch,bankId) => {
  dispatch({
    type: Actions.RETRIEVE_BANK_DETAILS,
    bankId: bankId});
};
export const clearBankDetailsAction = (dispatch) => {
  dispatch({
    type: Actions.CLEAR_SELECTED_BANK_DETAILS});
};


/////////////////////////
// EXPORTS AND DOWNLOADS
/////////////////////////
export const exportTableAction = (dispatch, fileType, fileName, data ) => {
  dispatch({type: Actions.EXPORT_TABLE_DATA, fileType:fileType, fileName:fileName, data:data});
};
export const exportBankDetailsAction = (dispatch, fileType, fileName, data, extras) => {
  dispatch({type: Actions.EXPORT_BANK_DETAILS_DATA, fileType:fileType, fileName:fileName, data:data, extras:extras});
}
export const downloadKeylinesImageAction = (dispatch, fileType, fileName, data) => {
  dispatch({type: Actions.DOWNLOAD_KEYLINES_IMAGE, fileType:fileType, fileName:fileName, data:data});
}
export const clearDownloadStateAction = (dispatch) => {
  dispatch({type: Actions.CLEAR_TABLE_DOWNLOAD_STATE});
}


/////////////////////////
// MAP CONFIG
/////////////////////////
export const hideMapAction = (dispatch,chartRef, mapSetter) => {
  dispatch({type: Actions.HIDE_MAP,ref: chartRef, mapSetter: mapSetter});
}
export const showMapAction = (dispatch,chartRef, mapSetter) => {
  dispatch({type: Actions.SHOW_MAP, ref: chartRef, mapSetter: mapSetter});
}
export const toggleMapAction = (dispatch,payload) => {
  dispatch({type: Actions.MAP_TOGGLE_COMPLETE, payload: payload});
}
export const setMinZoomAction = (dispatch,zoom) => {
  dispatch({type:Actions.SET_MIN_ZOOM,zoom: zoom});
}
export const setCombineByAction = (dispatch,combineField) => {
  dispatch({type:Actions.SET_COMBINE_BY_FIELD, combineField: combineField});
}
export const addClustersAction = (dispatch,chart, setMarkersPresent) => {
  dispatch({type:Actions.ADD_CLUSTERS, chart: chart, dispatch: dispatch, setMarkersPresent: setMarkersPresent});
}
export const removeClustersAction = (dispatch,chart,setMarkersPresent) => {
  dispatch({type:Actions.REMOVE_CLUSTERS, chart: chart, setMarkersPresent: setMarkersPresent});
}
export const setMapTilesAction = (dispatch,url) => {
  dispatch({type:Actions.SET_MAP_LAYER, url: url});
}
export const addShapeAction = (dispatch,shape,shapeId) => {
  dispatch({type:Actions.ADD_SHAPE, shape: shape, shapeId: shapeId});
}
export const removeShapeAction = (dispatch,shapeId) => {
  dispatch({type:Actions.REMOVE_SHAPE, shapeId: shapeId});
}
export const toggleDrawControlsAction = (dispatch) => {
  dispatch({type:Actions.TOGGLE_DRAW_CONTROLS});
};


////////////////////////
// KEYLINES DISPLAY
////////////////////////
export const setKeylinesForegroundData = (dispatch,data) => {
  dispatch({type: Actions.SET_FOREGROUND_KL_DATA, data: data});
}
export const setContextMenuAction = (dispatch, x, y, menuType, id, title) => {
  dispatch({type: Actions.SET_KEYLINES_CONTEXT_MENU, x: x, y: y, menuType: menuType, id: id, title: title});
}
export const clearContextMenuAction = (dispatch) => {
  dispatch({type: Actions.CLEAR_KEYLINES_CONTEXT_MENU});
}
export const singleOutNodeAction = (dispatch,id) => {
  dispatch({type: Actions.SINGLE_OUT_NODE,id: id});
}
export const setChartLoadedAction = (dispatch,payload) => {
  dispatch({type: Actions.SET_CHART_LOADED,payload: payload});
}
export const setLayoutAction = (dispatch,payload) => {
  dispatch({type: Actions.SET_CHART_LAYOUT,payload: payload});
}
export const chartSizeChangedAction = (dispatch,width,height) => {
  dispatch({type: Actions.CHART_SIZE_CHANGED, width: width, height: height})
} 

///////////////////////
// API DATA REQUESTS
///////////////////////
export const luceneSearchAction = (dispatch) => {
  dispatch({type: Actions.LUCENE_SEARCH});
}
export const retrieveNeighboursAction = (dispatch,bankId) => {
  dispatch({type: Actions.RETRIEVE_NEIGHBOURS_BY_ID, bankId: bankId});
}
export const updateSearchTermAction = (dispatch,searchTerm) => {
  dispatch({type: Actions.UPDATE_SEARCH_TERM, searchTerm: searchTerm});
}
export const updateSearchFilterAction = (dispatch,filterTerm) => {
  dispatch({type: Actions.UPDATE_SEARCH_FILTER, filterTerm: filterTerm});
}
export const updateFilteredDataAction = (dispatch,data) => {
  dispatch({type: Actions.UPDATE_FILTERED_DATA, data: data});
}
export const clearExceededResultsWarning = (dispatch) => {
  dispatch({type: Actions.API_NEIGHBORS_EXCEEDED, warning: ""});
}