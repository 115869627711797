/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import { toggleDrawControlsAction } from '../../../state/analyticsActions';
import PropTypes from 'prop-types';

const DrawControlsToggle = ( { chartMap, containerRef } ) => {
    const dispatch = useDispatch();
    const drawControlsOn = useSelector(state => state.analyticsReducer.keylinesConfig.drawControlsOn);
    const handleClick = () => {
        if (chartMap) {
            toggleDrawControlsAction(dispatch);
        }
      };
    return <div>
        <Tooltip
        PopperProps={{
            container: containerRef.current
        }}
        title={drawControlsOn ? 'Disable Draw Controls' : 'Enable Draw Controls'}
        color='primary'>
            <IconButton onClick={handleClick}>
                <CreateIcon />
            </IconButton>
        </Tooltip>
    </div>
}
DrawControlsToggle.propTypes = {
    chartMap: PropTypes.any,
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }
export default DrawControlsToggle;