/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapBounds, drawControl, layerNames, mapLayers} from '../../../../common/config/keylinesMapConfig';
import { 
  clearBankDetailsAction, 
  setKeylinesForegroundData, 
  addClustersAction, 
  removeClustersAction, 
  addShapeAction,
  removeShapeAction, 
  showMapAction,
  hideMapAction} from '../../state/analyticsActions';
import { selectIdAndForegroundNeighbours } from '../../../../common/utils/functions/keylines/selection';
import inBounds from '../../../../common/utils/functions/geo/inBounds';
import combineFields from '../../../../common/config/combineFields';
import { createShapeFromStore, prepShapeForStore, shapeNames } from '../../../../common/utils/functions/geo/shapeDrawing';
import * as L from 'leaflet';
import PropTypes from 'prop-types';


const MapController = ({chartRef}) => {
  const [leafletMap, setLeafletMap] = useState(null);
  const [leafletMarkersPresent, setLeafletMarkersPresent] = useState(false);
  const dispatch = useDispatch();

  const chartLoaded = useSelector(state => state.analyticsReducer.keylinesConfig.chartLoaded);
  const selectedId = useSelector(state => state.analyticsReducer.selectedBankDetails.bankId)
  const filteredData = useSelector(state => state.analyticsReducer.keylinesData);
  const mapOn = useSelector(state => state.analyticsReducer.keylinesConfig.mapVisible);
  const combineBy = useSelector(state => state.analyticsReducer.keylinesConfig.combineBy);
  const tilesUrl = useSelector(state => state.analyticsReducer.keylinesConfig.tilesUrl);
  const mapShapes = useSelector(state => state.analyticsReducer.keylinesConfig.mapShapes);
  const drawControlsOn = useSelector(state => state.analyticsReducer.keylinesConfig.drawControlsOn);


  const mapClickFunction = useCallback(() => {
    clearBankDetailsAction(dispatch);
  },[dispatch])


  const handleShapeRightClick = useCallback((clickEvent) => {
    /*
    Check shapeId stored on leaflet layer, then remove that ID's
    entry from the redux store.
    */
    if (clickEvent.sourceTarget) {
      const id = clickEvent.sourceTarget.shapeId;
      removeShapeAction(dispatch,id);
    }
  }, [dispatch])


  const drawEventFunction = useCallback((e) => {
    /*
    Takes leaflet shape from draw event, puts into format ready for redux, then sends
    it to the redux store.
    */
    var type = e.layerType;
    // layerType records shape e.g. circle, rectangle, marker, polyline, etc.
    var layer = e.layer;
    // extract info from the clicked shape, and put it in correct format for redux
    const [newShape,id] = prepShapeForStore(type,layer);
    if (newShape && id) {
      // add shape info to redux store
      if (type === shapeNames.polygon) {
        // leave a delay so that polygon drawing markers can be removed safely before chart reload
        setTimeout(() => addShapeAction(dispatch,newShape,id),300);
      } else {
        addShapeAction(dispatch,newShape,id);
      }
      
    }
  },[dispatch])


  const removeMapClickListener = useCallback(() => {
    if (leafletMap) {
      leafletMap.off('click')
    }
  },[leafletMap])


  const addMapClickListener = useCallback(() => {
    if (leafletMap) {
      leafletMap.on('click',mapClickFunction)
    }
  },[leafletMap,mapClickFunction])


  const mapResizer = useCallback(() => {
    /*
    Sets min zoom on the map such that users can't zoom out too far.
    Chosen fitZoom-1 to make sure user can still see full world if they want
    */
    if (leafletMap) {
      const fitZoom = leafletMap.getBoundsZoom(mapBounds,true)
      leafletMap.setMinZoom(fitZoom-1);
    }
  },[leafletMap])


  const foregroundEntriesInBounds = useCallback(
    () => {
      /*
      Returns any nodes that are within the current map viewbox.
      */
      if (leafletMap) {
        const bounds = leafletMap.getBounds();
        const northEast = bounds._northEast;
        const southWest = bounds._southWest;

        var inBoundsData = [];
        filteredData.items.forEach(item => {
          if (item.type === 'node'
          && item.pos.lat !== 'unknown'
          && inBounds(item.pos.lat,item.pos.lng,northEast,southWest)) {
            inBoundsData.push(item)
          }
        })
        // Update results table data with currently visible banks
        setKeylinesForegroundData(dispatch,
          {type: 'LinkChart',
          items: inBoundsData});
      }
    },
    [leafletMap,filteredData, dispatch],
  )


  const registerFilterByMapBoundsListener = useCallback((register) => {
    /*
    Add a moveend listener that sets foreground (results table) data visible in the current
    map viewbox.
    */
    if (leafletMap) {
      if (register) {
        leafletMap.on('moveend', foregroundEntriesInBounds);
      } else {
        leafletMap.off('moveend', foregroundEntriesInBounds);
      }
    }
  },[leafletMap,foregroundEntriesInBounds])


  useEffect(() => {
    /*
    Impose zoom limits and filter by viewbox when in proximity clustering mode
    */
    if (chartLoaded) {
      if (leafletMap) {
        // control min zoom level to minimise empty space on map
        mapResizer();
        // when proximity clusters are showing, register a moveend listener
        // on the leaflet map, which updates results table with entries that are in
        // the current viewbox.
        // If not in proximity clustering mode, remove the listener
        if (combineBy === combineFields.proximity) {
          leafletMap.off('moveend');
          registerFilterByMapBoundsListener(true);
        } else {
          registerFilterByMapBoundsListener(false);
        }
      }
    }
    
  }, [leafletMap,registerFilterByMapBoundsListener,combineBy,mapResizer,chartLoaded])


  useEffect(() => {
    /*
    check whether a bank is selected, and highlight it if so
    */
    if (chartRef) {
      if (selectedId) {
        var selectedBankIncluded = false;
        for (var i=0; i<filteredData.items.length; i++) {
          if (filteredData.items[i].type === 'node') {
            if (filteredData.items[i].id === selectedId) {
              selectedBankIncluded = true;
              break;
            }
          }
        }
        if (!selectedBankIncluded) {
          clearBankDetailsAction(dispatch);
        }
        if (combineBy !== combineFields.proximity) {
          if (chartRef.getItem(selectedId)) {
            selectIdAndForegroundNeighbours(
              selectedId,chartRef,dispatch,filteredData);
          } 
        }
      } else {
        if (combineBy === combineFields.proximity) {
          if (leafletMap) {
            if (Object.keys(leafletMap._panes).length > 0) {
              foregroundEntriesInBounds();
            }
          }
        } else {
          selectIdAndForegroundNeighbours(null,
            chartRef,
            dispatch,
            filteredData);
        }
      }
    }
  }, [selectedId,chartRef,dispatch,leafletMap,filteredData,foregroundEntriesInBounds,combineBy])
  

  useEffect(() => {
    /*
    tile flipping logic, fires whenever tilesUrl is changed
    */
    if (chartLoaded) {
      if (leafletMap) {
        // iterate over map layers
        if (leafletMap._layers) {
          if (Object.keys(leafletMap._layers).length > 0) {
            leafletMap.eachLayer(layer => {
              // if layer has _tiles prop, it is the tile layer, change it to desired tile url
              if (layer._tiles) {
                layer.setUrl(tilesUrl)
              }
            })
          }
        }
      }
    }
  }, [tilesUrl,leafletMap,chartLoaded])


  useEffect(() => {
    /*
    Background colour changer, change when tiles are dark. One day, change with whole theme.
    */
    if (chartLoaded && chartRef) {
      // For now, while we don't have full app theme switch to also control chart items style
      // just make network view light background regardless of map tiles
      if (!mapOn) {
        chartRef.options({backColour: "#fff"});
      } else {
        if (tilesUrl === mapLayers[layerNames.light]
            || tilesUrl === mapLayers[layerNames.satellite] ) {
          chartRef.options({backColour: "#fff"});
        } else if (tilesUrl === mapLayers[layerNames.dark]) {
          chartRef.options({backColour: "black"});
        }
      }
    }
  }, [tilesUrl,mapOn,chartLoaded,chartRef])


  useEffect(() => {
    /* add drawing listener
    triggers when leafletMap loaded 
    */
    if (chartLoaded) {
      if (leafletMap) {
        // register listener on leafletMap to fire when a shape is drawn
        leafletMap.on('draw:created', drawEventFunction);
        // clicking on empty part of map should des
        if (combineBy === combineFields.proximity) {
          // register listeners for proximity mode where keylines chart doesn't handle any events
          leafletMap.on('click', mapClickFunction);
          leafletMap.on('draw:drawstart', removeMapClickListener);
          leafletMap.on('draw:drawstop',addMapClickListener)
        } else {
          // remove leaflet only listeners, should now be handled by keylines
          leafletMap.off('click');
          leafletMap.off('draw:drawstart');
          leafletMap.off('draw:drawstop');
        }
      }
    }
    
  }, [dispatch, leafletMap,chartLoaded,drawEventFunction,mapClickFunction,removeMapClickListener,addMapClickListener,combineBy])


  useEffect(() => {
    /*
    Handle clustering logic, triggered when combining field or data is changed
    */
    if (chartRef) {
      if (chartLoaded) {
        if (combineBy !== combineFields.correspondent) {
          // this is where logic for other combo types would go too
          if (combineBy === combineFields.proximity ) {
              if (leafletMap) {
                if (Object.keys(leafletMap._panes).length > 0) {
                  addClustersAction(dispatch,chartRef,setLeafletMarkersPresent);
                }
              }
          }
        } else if (combineBy === combineFields.correspondent) {
          // if cluster markers
          // remove proximity clusters from leaflet map
          if (leafletMarkersPresent) {
            removeClustersAction(dispatch,chartRef,setLeafletMarkersPresent)
          }
    
          // TODO: add when trying other combine fields like parentBank
          // if any combos
          // remove any other combos
    
          // if either of previous steps were done
          // re-merge original chart data
        }
      }
      
    }
  }, [combineBy,filteredData,dispatch,chartLoaded,chartRef,leafletMap,leafletMarkersPresent,selectedId]);

  
  useEffect(() => {
    /*
    When a shape is added or removed from the store, update rendered shapes accordingly.

    */
    if (chartLoaded) {
      if (leafletMap) {
        var editableLayers = new L.FeatureGroup();
        // iterate over shapes in redux store
        if (Object.keys(mapShapes)) {
          Object.keys(mapShapes).forEach(shapeId => {
            const shape = mapShapes[shapeId];
            // create a leaflet shape layer based on redux shape info
            const leafletShape = createShapeFromStore(shape);
            if (leafletShape !== null) {
              // Add id and listeners on shape, then add shape to visible layer
              leafletShape.shapeId = shapeId;
              leafletShape.on('contextmenu', handleShapeRightClick);
              editableLayers.addLayer(leafletShape);
            }
          })
        }
        if (Object.keys(leafletMap._panes).length > 0) {
          // Add shapes layer to map. This is the part that makes shapes visible.
          leafletMap.addLayer(editableLayers);
        }
      }
    }
  }, [mapShapes, handleShapeRightClick,leafletMap,chartLoaded])
  

  useEffect(() => {
    /*
    Toggle drawing controls, when redux state changes add/remove controls accordingly.
    Drawing controls should only be visible/interactable when the map exists.
    */
    if (chartLoaded) {
      if (leafletMap) {
        if (drawControlsOn) {
          if (Object.keys(leafletMap._panes).length > 0) {
            leafletMap.addControl(drawControl);
          } 
        } else {
          if (Object.keys(leafletMap._panes).length > 0) {
            leafletMap.removeControl(drawControl);
          }
        }
      }     
    }
  }, [chartLoaded, drawControlsOn,leafletMap])


  useEffect(() => {
    /* 
    when data changes, check that user is kept on correct visualization
    enforce that graph matches redux prescribed state
    */
    if (chartRef) {
        if (chartLoaded) {
            if (typeof chartRef.map === 'function') {
              const shown = chartRef.map().isShown();
              if (shown !== mapOn) {
                if (mapOn) { 
                  showMapAction(dispatch,chartRef,setLeafletMap);
                } else {
                  hideMapAction(dispatch,chartRef,setLeafletMap);
                }
              } 
            }
        }
    }
  }, [mapOn,filteredData,chartLoaded,chartRef,dispatch]);


  return (
    <React.Fragment></React.Fragment>
  )
}
MapController.propTypes = {
  chartRef: PropTypes.any,
}

export default MapController;