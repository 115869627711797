/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as KeyLines from 'keylines';
import 'leaflet';

function invoke(fn, ...args) {
  return (typeof fn === 'function') ? fn(...args) : undefined;
}

// This is the lowest level wrapper of the KeyLines integration - it deals with loading of the
// KeyLines component, options, resizing and raising KeyLines events up

function createKeyLinesComponent(type, onLoadFn) {
  class KlComponent extends React.Component {
    constructor(props) {
      super(props);
      KeyLines.promisify();
      this.type = type;
      this.onLoad = onLoadFn;
      // Bind our class specific functions to this
      // See: https://facebook.github.io/react/docs/react-without-es6.html#autobinding
      ['applyProps', 'setSelection', 'onEvent', 'onLoad'].forEach((prop) => {
        this[prop] = this[prop].bind(this);
      });
    }

    componentDidMount() {
      const componentDefinition = {
        container: this.klElement,
        type: this.type,
        options: this.props.options,
      };

      KeyLines.create(componentDefinition).then((component) => {
        this.component = component;
        this.component.on('all', this.onEvent);
        this.applyProps().then(() => {
          // Finally, tell the parent about the component so it can call functions on it
          invoke(this.props.ready, this.component, this.klElement);
        });
      });
    }

    componentWillUnmount() {
      // Clean up the component
      if (this.component) this.component.destroy();
    }

    setSelection() {
      // This works because the selectionchange event is not raised when changing selection
      // programmatically
      try {
        const selectedItems = this.component.selection(this.props.selection);
        if (this.type === 'chart' && selectedItems.length > 0) {
          this.component.zoom('selection', { animate: true, time: 250 });
        }
      } catch (err) {
        console.log('error in chart selection')
      }
      
    }

    async updateFunction(prevProps) {

      if (this.component) {
        // we need to intercept the options being set and pass them on to the component manually
        if (this.props.options && this.props.options !== prevProps.options) {
          try { 
            await this.component.options(this.props.options)
          } // don't worry about callback here
          catch (err) {
            console.log('error applying chart options')
            console.log(err)}
          }
        }

        const reload = this.props.data !== prevProps.data;
        if (reload) {
          // note applyProps also deals with selection
          try {
            await this.applyProps();}
          catch (err) {
            console.log('error applying props')
            console.log(err)}
        } else if (this.props.selection || prevProps.selection) {
          if (this.props.selection !== prevProps.selection) {
            try { 
              await this.setSelection()
            }
            catch (err) {
              console.log('error setting selection')
              console.log(err)}
          }
        }
      }

    async componentDidUpdate(prevProps) {
      try {
        if (this.props.mounted) {
          await this.updateFunction(prevProps);
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    // this looks for a handler with the right name on the props, and if it finds
    // one, it will call it with the event arguments.
    onEvent(props) {
      return invoke(this.props[props.name], props.event);
    }

    // this applies all the component related props except the options which are
    // handled differently
    async applyProps() {
      try {
        await this.component.load(this.props.data)
      } catch (err) {
        console.log('error loading chart')
      } 

      try {
        await this.onLoad({ animate: !!this.props.animateOnLoad },this.props.setChartLoaded, this.props.layout)
      } catch (err) {
        console.log('error in onload function');
      }
      try {
        await this.component.selection(this.props.selection);
      } catch (err) {
        console.log('error making selection')
      }
    }

    render() {
      const { containerClassName, style, children } = this.props;
      return (
        <div 
        ref={(klElement) => { this.klElement = klElement; }} 
        className={containerClassName}
        id="chart"
        style={style}
        >
          {children}
        </div>
      );
    }
  }

  // defaultProps has to be a static property of the component class
  KlComponent.defaultProps = {
    data: {},
    animateOnLoad: false,
    options: {},
    selection: [],
  };

  KlComponent.propTypes = {
    // data in the chart or time bar data format
    data: PropTypes.object,
    // specifies whether the layout on loading is animated
    animateOnLoad: PropTypes.bool,
    // chart or time bar options object
    options: PropTypes.object,
    // array of ids specifying the selected items
    selection: PropTypes.array,
    // called when the chart or time bar is fully loaded
    ready: PropTypes.func,
    // the style for the chart or time bar div
    style: PropTypes.object,
    // the CSS class name for the div containing the chart or time bar
    containerClassName: PropTypes.string,
    //  NOTE: you can add props at the level above this - e.g., 'click'
    //        i.e.,   <Chart click={this.clickHandler}/>
    mounted: PropTypes.bool,
    setChartLoaded: PropTypes.func,
    layout: PropTypes.string,
    children: PropTypes.any,
  };

  return KlComponent;
}

function createChartComponent() {
  function onLoad(options,setChartLoaded,layout) {
    setChartLoaded(true);
    // default behaviour when loading data in the chart
    return this.component.layout(layout, options);
  }

  return createKeyLinesComponent('chart', onLoad);
}

// Define the Chart component
export const Chart = createChartComponent();


function createTimebarComponent() {
  function onLoad(options) {
    // default behaviour when loading data in the timebar
    return this.component.zoom('fit', options);
  }

  return createKeyLinesComponent('timebar', onLoad);
}

// Define the Timebar component
export const Timebar = createTimebarComponent();
