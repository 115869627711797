/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { IconButton, Menu, Tooltip } from '@material-ui/core';
import React from 'react';
import Icon from '@mdi/react';
import { mdiGraphql } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutAction } from '../../../state/analyticsActions';
import layouts from '../../../../../common/config/chartLayouts';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';

const NetworkLayoutMenu = ( { chart, containerRef } ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const currentLayout = useSelector(state => state.analyticsReducer.keylinesConfig.layout);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl)
    const changeLayout = (layout) => {
        if (chart) {
            // use .then to take action after asynchronous layout has happened
            chart.layout(layout, {tightness: 5})
        }
    };

    const handleItemClick = (event,newLayout) => {
        if (newLayout !== null) {
            changeLayout(newLayout);
            setLayoutAction(dispatch,newLayout);
        } else {
            changeLayout(currentLayout);
        }
        
    }

    return <div>
        <Tooltip
        PopperProps={{
            container: containerRef.current
        }}
        title='Choose Layout'
        color='primary'>
            <IconButton onClick={handleClick}>
                <Icon path={mdiGraphql} size={1}/>
            </IconButton>
        </Tooltip>
        <Menu
        container={containerRef.current}
        // this is important to avoid adding padding to body div
        disableScrollLock={true}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={open}
        onClose={handleClose}>
            <ToggleButtonGroup
            value={currentLayout}
            exclusive
            orientation='vertical'
            onChange={handleItemClick}
            >
            {Object.values(layouts).map(layout => {
                return <ToggleButton 
                value={layout}
                key={layout}>
                        {layout}
                    </ToggleButton>
            })}
            </ToggleButtonGroup>
        </Menu>
    </div>
}
NetworkLayoutMenu.propTypes = {
    chart: PropTypes.any,
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }
export default NetworkLayoutMenu;