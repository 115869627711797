/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

const layouts = {
    standard: 'standard',
    lens: "lens",
    organic: "organic",
    sequential: "sequential",
}

export default layouts;