/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PublicIcon from '@material-ui/icons/Public';
import TimelineIcon from '@material-ui/icons/Timeline';
import { setCombineByAction, toggleMapAction } from '../../../state/analyticsActions';
import combineFields from '../../../../../common/config/combineFields';
import PropTypes from 'prop-types';

const MapToggleButton = ( { containerRef } ) => {
    const dispatch = useDispatch();
    
    const mapOn = useSelector(state => state.analyticsReducer.keylinesConfig.mapVisible);
    const combineBy = useSelector(state => state.analyticsReducer.keylinesConfig.combineBy);
    const handleClick = () => {
        if (mapOn) {
            // turn off proximity cluster before returning to network view
            if (combineBy === combineFields.proximity) {
                setCombineByAction(dispatch,combineFields.correspondent);
            }
            toggleMapAction(dispatch,false);
        } else {
            toggleMapAction(dispatch,true);
            setCombineByAction(dispatch,combineFields.proximity);
        }
      };
    return <div>
        {mapOn
        ? < Tooltip
        PopperProps={{
            container: containerRef.current
        }}
        title='Network View'
        color='primary'>
            <IconButton onClick={handleClick}>
                <TimelineIcon />
            </IconButton>
        </Tooltip>
        : < Tooltip
        PopperProps={{
            container: containerRef.current
        }}
        title='Map View'
        color='primary'>
            <IconButton onClick={handleClick}>
                <PublicIcon />
            </IconButton>
        </Tooltip>}
    </div>
}
MapToggleButton.propTypes = {
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }
export default MapToggleButton;