/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Paper, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { retrieveBankDetailsAction } from '../../state/analyticsActions';
import { DownloadDialog } from '../../../../common/components/download/DownloadDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { databaseMapping } from '../../../../common/config/databaseFieldsMappings';
import {tableOptions} from '../../../../common/config/fileTypeOptions'

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#eeeeee',
      },
    selectedBankIndicator: {
        backgroundColor: '#f58120',
    },
    bufferColumn: {
        width: theme.spacing(1),
        padding: theme.spacing(0.5),
    }
}))



const ResultsTable = () => {
    const [downloadOpen, setDownloadOpen] = React.useState(false);
    const handleDownloadOpen = () => {
        setDownloadOpen(true);
    }

    const classes = useStyles();
    const dispatch = useDispatch();

    const keylinesData = useSelector(state => state.analyticsReducer.keylinesForegroundData);
    const selectedBankId = useSelector(
        state => state.analyticsReducer.selectedBankDetails.bankId);
    const dataSelector = useSelector(state => state.analyticsReducer.tableCSVdownload.data);
    const successSelector = useSelector(state => state.analyticsReducer.tableCSVdownload.success);
    const headers = {
        BufferCol: '',
        [databaseMapping['Institution Name'].dbName]: databaseMapping['Institution Name'].displayName,
        [databaseMapping['Country'].dbName]: databaseMapping['Country'].displayName,
        [databaseMapping['State/Province'].dbName]: databaseMapping['State/Province'].displayName,
        [databaseMapping['City'].dbName]: databaseMapping['City'].displayName,
        [databaseMapping['SWIFT/BIC'].dbName]: databaseMapping['SWIFT/BIC'].displayName,
        icon: <React.Fragment>
        <Tooltip title="Download" placement="right">
            <IconButton color='inherit' onClick={handleDownloadOpen}>
                <GetAppIcon />
            </IconButton>
        </Tooltip>
        <DownloadDialog
        dialogOpen={downloadOpen}
        setDialogOpen={setDownloadOpen}
        fileTypeOptions={tableOptions}
        targetVisualization={"Results"}
        dataToExport={dataSelector}
        successSelector={successSelector}/>
        </React.Fragment>
    }
    let rowCounter = 0;
    let idArray = [];
    
    return (
        <TableContainer style={{position: 'relative',maxHeight: "100%"}} id="resultsTable">
            <Table
            stickyHeader
            aria-label="simple table"
            size="small"
            component={Paper}>
                <TableHead>
                    <TableRow>
                        {Object.keys(headers).map((header,index) => {
                            return <TableCell
                            style={{fontSize: "16pt"}}
                            key={header}
                            className={
                                clsx(
                                    index === 0 && classes.bufferColumn,
                                    header === 'icon' && classes.bufferColumn)}>
                                {headers[header]}
                            </TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                {keylinesData.items.length > 0 && keylinesData.items.map((node) => {
                    if (node.type === 'node') {
                        const evenRow = rowCounter % 2 === 1;
                        rowCounter += 1;
                        // protect against duplicate entries showing up
                        if (idArray.includes(node.id)) {
                            return null;
                        } else {
                            idArray.push(node.id)
                            return <TableRow
                            key={node.id}
                            className={clsx(evenRow && classes.evenRow)}
                            onClick={() => retrieveBankDetailsAction(dispatch,node.id)}
                            >
                                {Object.keys(headers).map((header,index) => {
                                    return <TableCell
                                    key={header}
                                    className={
                                        clsx(
                                            node.id === selectedBankId && index === 0 && classes.selectedBankIndicator,
                                            index === 0 && classes.bufferColumn)}>
                                                {node.d[header]}
                                    </TableCell>
                                })}
                            </TableRow>
                        }
                        
                        
                    }
                    return null
                })}
                </TableBody>
            </Table>
        </TableContainer>
        );
}

export default ResultsTable;