/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Box from "@material-ui/core/Box";
import { Paper, Button, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { updateLoginInfoAction, loginUserAction } from '../../state/loginActions';
import PropTypes from 'prop-types';

export default function LoginForm({path}) {
  const dispatch = useDispatch();

  const email = useSelector((state) => state.loginReducer.login.email)
  const pass = useSelector((state) => state.loginReducer.login.pass)

  const handleEmailChange = (event) => {
    updateLoginInfoAction(dispatch,'email',event.target.value);
  };
  const handlePassChange = (event) => {
    updateLoginInfoAction(dispatch,'pass',event.target.value);
  };
  const requestForgottenPasswordReset = () => {
    updateLoginInfoAction(dispatch,'error','');
    updateLoginInfoAction(dispatch,'passResetRequested',true);
  }
  const attemptLogin = () => {
    loginUserAction(dispatch);
  }
  
  return (
        <Paper>
          <Typography variant='h2'>
            LOGIN
          </Typography>
          <Box
          justifyContent="center"
          alignItems="center">
            <TextField
            required
            id="outlined-required"
            label="Email"
            placeholder="email@example.com"
            variant="outlined"
            color='secondary'
            margin="normal"
            value={email}
            onChange={handleEmailChange}/>
          </Box>
          <Box>
            <TextField
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            color='secondary'
            margin="normal"
            value={pass}
            onChange={handlePassChange}/>
          </Box>
          <Box>
            <Link href={`#${path}`} onClick={requestForgottenPasswordReset}>
              <Typography> 
                Forgotten Password?
              </Typography>
            </Link>
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={attemptLogin}>
                Sign in
            </Button>
          </Box>
        </Paper>
  )
}
LoginForm.propTypes = {
  path: PropTypes.string,
}