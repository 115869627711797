/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { ListItem, Tooltip} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { luceneSearchAction, updateSearchTermAction } from '../../state/analyticsActions';
import PropTypes from 'prop-types';

export const DrawerSearch = ({ handleOpen, style, expanded }) => {
    const dispatch = useDispatch();
    const searchTerm = useSelector(state => state.analyticsReducer.search.value);

    const handleChange = (event) => {
        updateSearchTermAction(dispatch,event.target.value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            luceneSearchAction(dispatch);
        }
    }
    return expanded
    ? (<ListItem>
            <TextField
                id="input-with-icon-textfield"
                value={searchTerm}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>)}}
            />
        </ListItem>)
    : (<ListItem button onClick={handleOpen}>
        < Tooltip 
         title="Text Search" placement="right">
            <SearchIcon className={style}/>
        </Tooltip>
    </ListItem>)
}
DrawerSearch.propTypes = {
    handleOpen: PropTypes.func,
    expanded: PropTypes.bool,
    style: PropTypes.string,
}