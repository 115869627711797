/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    classbannerDiv: {
        width: "100%",
        alignItems: "center",
        height: theme.overrides.ClassificationBanner.height,
        zIndex: theme.zIndex.drawer + 1,
        left: 0,
        position: "absolute",
      },
    bannerText: {
      verticalAlign: "middle",
      width: "auto",
      textAlign: "center",
      color: theme.palette.on.white70,
      margin: "auto 0",
    },
    footer: {
        bottom: 0,
    },
    header: {
      top: 0,
      backgroundColor: "black",
    }
  }));
const ClassificationBanner = ({footer}) => {
const classes = useStyles();
const classification = (
    <Typography className={classes.bannerText} >
        Illuminate Proprietary
    </Typography>
    )
return (
    <div className={clsx(classes.classbannerDiv,footer ? classes.footer : classes.header)}>{classification}</div>
);
};
ClassificationBanner.propTypes = {
  footer: PropTypes.bool,
}
export default ClassificationBanner;