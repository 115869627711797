/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from '../../../../thirdparty/keylines/react/react-keylines';
import { LeafletSettings, KeyLineChartOptions, mapBounds,} from '../../../../common/config/keylinesMapConfig';
import { 
  clearBankDetailsAction, 
  retrieveBankDetailsAction, 
  setKeylinesForegroundData, 
  setContextMenuAction,
  setChartLoadedAction,} from '../../state/analyticsActions';
import ControlRail from './controls/ControlRail';
import { selectIdAndForegroundNeighbours } from '../../../../common/utils/functions/keylines/selection';
import combineFields from '../../../../common/config/combineFields';
import ShapeFilteringComponent from './ShapeFilteringComponent';
import MapController from './MapController';
import PropTypes from 'prop-types';


const NetworkChart = ( { containerStyling, containerRef } ) => {
  const [chartRef, setChartRef] = useState(null);
  const dispatch = useDispatch();  

  const combineBy = useSelector(state => state.analyticsReducer.keylinesConfig.combineBy);
  const tilesUrl = useSelector(state => state.analyticsReducer.keylinesConfig.tilesUrl);
  const filteredData = useSelector(state => state.analyticsReducer.keylinesData);
  const user = useSelector(state => state.userReducer.user);
  const layout = useSelector(state => state.analyticsReducer.keylinesConfig.layout);


  const setChartLoaded = useCallback(() => {
    setChartLoadedAction(dispatch,true);
  },[dispatch])


  const createContextMenu = useCallback(
    ({ id, x, y }) => {
      if (chartRef) {
      // currently use this to stop the menu turning up when clicking on leaflet markers
      // that aren't actual chart nodes
        if (chartRef.getItem(id)) {
          const item = chartRef.getItem(id);
          const type = item.type;
          const title = item.t;
          if (type === 'node') {
            setContextMenuAction(dispatch,x,y,type, id, title);
          }
        }
      }
    },
    [dispatch,chartRef],
  )


  const loadedChart = (chart) => {
    chart.options(KeyLineChartOptions);
    setKeylinesForegroundData(dispatch,filteredData);
    chart.map().options({
      leaflet: LeafletSettings,
      tiles: {
        url: tilesUrl,
        bounds: mapBounds,
        noWrap: true},
      animate: true,
    });
    setChartRef(chart);
    setChartLoadedAction(dispatch,true);
  }


  const clickHandler = ({ id }) => {
    // when in proximity mode, clicks are handled by leaflet markers
    // instead of keylines
    if (combineBy !== combineFields.proximity) {
      if (chartRef) {
        if (chartRef.getItem(id)) {
          // if selected item is a node (not a link), use its id as
          // selected bank id 
          // NOTE: this could change if we introduce node combos 
          // and they behave differently in some way
          if (chartRef.getItem(id).type === 'node'){
            retrieveBankDetailsAction(dispatch,id);
            selectIdAndForegroundNeighbours(id,
              chartRef,
              dispatch,
              filteredData);
          } else {
            clearBankDetailsAction(dispatch);
            selectIdAndForegroundNeighbours(id,
              chartRef,
              dispatch,
              filteredData);
          }
      } else {
        clearBankDetailsAction(dispatch);
        selectIdAndForegroundNeighbours(null,
          chartRef,
          dispatch,
          filteredData);
        }
      } 
    }
  }


  return (
    <React.Fragment>
    <ControlRail 
        chart={chartRef ? chartRef : null}
        chartMap={chartRef ? chartRef.map() : null}
        containerRef={containerRef}
        />
      <Chart
          // can include events here, such as click, can find list of events 
          // in keylines docs
          data={filteredData}
          ready={loadedChart}
          containerClassName={containerStyling}
          click={clickHandler}
          context-menu={createContextMenu}
          setChartLoaded={setChartLoaded}
          layout={layout}
          // This prop is a temporary fix in place until we can properly handle the 
          // keylines self-destruction on logout
          mounted={Boolean(user)}
        >
      <ShapeFilteringComponent />
      <MapController chartRef={chartRef} />
        </Chart>
    </React.Fragment>
  )
}
NetworkChart.propTypes = {
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  containerStyling: PropTypes.string,
}

export default NetworkChart;