/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { TableContainer, Table, TableRow, TableCell, TableBody, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { bankDetailsGroups, subgroups } from '../../../../common/config/defaultBankDetails';
import ExpandableTableEntry from './ExpandableTableEntry';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        whiteSpace: "normal",
        wordBreak: "break-word"
    },
    TableContainer: {
        flex: 1,
    },
    evenRow: {
        backgroundColor: '#eeeeee',
      },
    fieldCell: {
        padding: theme.spacing(1)
    },
}));

const makeTableEntry = (name,value,cellClass,evenRowClass='',fieldCellClass) => {
    return (
    <TableRow key={name} className={evenRowClass ? evenRowClass : ''}>
        <TableCell component="th" scope="row" className={clsx(fieldCellClass)}>
            <Typography>{name}</Typography>
        </TableCell>
        <TableCell className={cellClass} align="right">
            <Typography>{value}</Typography>
        </TableCell>
    </TableRow>
    );
};

export const makeList = (payload,cellClass,evenRowClass) => {
    let rows = [];
    if (payload !== null) {
        if (Array.isArray(payload)) { // rows will have a single value
            rows = payload.map((member,idx) => {
                const evenRow = idx % 2 === 1;
                return [
                    ...rows,
                    makeTableEntry(member,'',cellClass,evenRow && evenRowClass)]
                }
            );
        } else if ( Object.keys(payload).length > 0 ) { // rows will have key-value pairs
            let count = 0;
            for (let [rating, value] of Object.entries(payload)) {
                const evenRow = count % 2 === 1;
                count += 1;
                rows.push(
                    makeTableEntry(rating, value, cellClass,evenRow && evenRowClass)
                )
            }
        } else {
            rows = null
        }
    }
    return rows;
};

const makeAdditionalInfoEntry = (AdditionalObject,cellClass,evenRowClass,name) => {
    let propsObject = {...AdditionalObject}
    return (
        <ExpandableTableEntry AdditionalObject={propsObject} cellClass={cellClass} evenRowClass={evenRowClass} name={name}
        />
    );
};

const BankDetailsTable = ({
    keysArray,
    activeStep,
}) => {
    const classes = useStyles();
    const selectedBankDetails = useSelector(
        state => state.analyticsReducer.selectedBankDetails.details);
    let visibleRowTally = 0;

    return (
        <React.Fragment>
        <TableContainer
        className={classes.TableContainer} >
            <Table aria-label="simple table" component={Paper} >
                <TableBody>
                {/* iterate over info returned from query, these keys will be stuff like
                street, city, etc. */}
                {Object.keys(selectedBankDetails).map((key) => {
                    /* check whether info is of the same group as the current tab
                    stuff like Location, Banking Info, Threat Profile, etc. */
                    if (selectedBankDetails[key].group === keysArray[activeStep].name) {
                        /* even row occurs on %2 == 1 because of array indexing, so index one is actually the 
                        second entry etc. */
                        const evenRow = visibleRowTally % 2 === 1;
                        visibleRowTally += 1;
                        /* Special case for board members tab, this info is returned as an
                        array, so instead of one entry for the array, render an entry for each member */
                        if (selectedBankDetails[key].group === bankDetailsGroups.boardMembersGroup.name) {
                            return makeList(selectedBankDetails[key].value,classes.tableCell,classes.evenRow);
                        } else if (selectedBankDetails[key].subgroup === subgroups.additionalInfoGroup) {
                            return makeAdditionalInfoEntry(
                                selectedBankDetails[key].value,
                                classes.tableCell,classes.evenRow,
                                selectedBankDetails[key].name
                        )}
                        else {
                            return makeTableEntry(
                                selectedBankDetails[key].name,
                                selectedBankDetails[key].value, 
                                classes.tableCell,
                                evenRow && classes.evenRow,
                                classes.fieldCell)
                        }}
                    return null})}
                </TableBody>
            </Table>
        </TableContainer>
        </React.Fragment>
      );
};
BankDetailsTable.propTypes = {
    keysArray: PropTypes.array,
    activeStep: PropTypes.number,
  }

export default BankDetailsTable;