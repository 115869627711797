/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { AboutDialog } from '../UpperRightHamburger/AboutDialog';
import { HelpDialog } from '../UpperRightHamburger/HelpDialog';
import { ReferenceDialog } from '../UpperRightHamburger/ReferenceDialog';
import { signOutAction } from '../../../../../common/state/user/userActions';

const HamburgerMenu = () => {

  const dispatch = useDispatch();
      const [aboutOpen, setAboutOpen] = React.useState(false);
      const [helpOpen, setHelpOpen] = React.useState(false);
      const [refOpen, setRefOpen] = React.useState(false);
      const [anchorEl, setAnchorEl] = React.useState(null);

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const handleAboutOpen = () => {
        setAboutOpen(true);
      };
      const handleAbout = () => {
        handleAboutOpen();
        handleClose();
      }
      const handleHelpOpen = () => {
        setHelpOpen(true);
      };
      const handleHelp = () => {
        handleHelpOpen();
        handleClose();
      }
      const handleRefOpen = () => {
        setRefOpen(true);
      };
      const handleRef = () => {
        handleRefOpen();
        handleClose();
      }
      const handleLogout = () => {
        signOutAction(dispatch);
      };
      const open = Boolean(anchorEl)

      return (
        <React.Fragment>
            <IconButton color="inherit" onClick={handleClick}>
                {open ? <MenuOpenIcon/> : <MenuIcon />}
              </IconButton>
            <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={open}
            onClose={handleClose}>
              <MenuItem onClick={handleAbout}>
                <Typography variant="body2">
                  About
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleHelp}>
                <Typography variant="body2">
                  Help
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleRef}>
                <Typography variant="body2">
                  Reference
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography variant="body2">
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          <AboutDialog
          dialogOpen={aboutOpen}
          setDialogOpen={setAboutOpen}/>
          <HelpDialog
          dialogOpen={helpOpen}
          setDialogOpen={setHelpOpen}/>
          <ReferenceDialog
          dialogOpen={refOpen}
          setDialogOpen={setRefOpen}/>
          </React.Fragment>
      )
  }

export default HamburgerMenu;