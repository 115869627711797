/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Dialog,
DialogActions,
DialogContent,
DialogTitle,
Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const AboutDialog = ({
  dialogOpen,
  setDialogOpen,
}) => {

      const handleDialogClose = () => {
        setDialogOpen(false)
      }

    return (
      
        <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        style={{textAlign:"center"}}
        overlayStyle={{backgroundColor: 'white'}}>
            <DialogTitle>
              <Typography variant="h4">
                RAN$ACK powered by Vigilance™
              </Typography>
            </DialogTitle>
            <DialogContent style={{minHeight:"40vh"}}>
              <Typography>
                <br/>RAN$ACK was designed for Counter Threat Finance Analysts by Counter Threat Finance Analysts to deliver illuminated data in order to accelerate informed decisions.
              </Typography>
              <Typography>
                <br/>RAN$ACK graphically displays data enabling link/relationship analysis coupled with geospatial/temporal analysis allowing analysts to understand and present analytical assessments in a manner of unparalleled clarity.
              </Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                variant='contained' 
                onClick={handleDialogClose} 
                color="primary" 
                autoFocus>
                OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
AboutDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
}