/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab/';
import { ListItem, Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchFilterAction } from '../../state/analyticsActions';
import PropTypes from 'prop-types';

export const DrawerFilters = ({ handleOpen, style, expanded }) => {

  const dispatch = useDispatch();
  const filterTerm = useSelector(state => state.analyticsReducer.search.filter);

  const handleFilter = (filterEvent, newFilter) => {
      if (newFilter === null) {
        updateSearchFilterAction(dispatch,"")
      } else {
        updateSearchFilterAction(dispatch,newFilter)
      }
  }

    return expanded
    ? (<React.Fragment>
        <ListItem>
            <Typography variant='h5'>
                Filters
            </Typography>
        </ListItem>
        <ListItem>
            <ToggleButtonGroup orientation="vertical" value={filterTerm} exclusive onChange={handleFilter}>
                <ToggleButton value="city" style={{width:'12.9rem'}}> City </ToggleButton>
                <ToggleButton value="country" style={{width:'12.9rem'}}> Country </ToggleButton>
                <ToggleButton value="currency" style={{width:'12.9rem'}}> Currency </ToggleButton>
                <ToggleButton value="name" style={{width:'12.9rem'}}> Name </ToggleButton>
            </ToggleButtonGroup>
        </ListItem>
    </React.Fragment>)
    : (<ListItem button onClick={handleOpen} >
        < Tooltip 
         title="Filters" placement="right">
            <FilterListIcon className={style}/>
        </Tooltip>
    </ListItem>)
}

DrawerFilters.propTypes = {
    handleOpen: PropTypes.func,
    expanded: PropTypes.bool,
    style: PropTypes.string,
}