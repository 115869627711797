/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  updateFilteredDataAction, 
  setChartLoadedAction,
  } from '../../state/analyticsActions';
import { filterByMapShapes } from '../../../../common/utils/functions/geo/shapeDrawing';

const ShapeFilteringComponent = () => {
  const dispatch = useDispatch();
  
  const returnedData = useSelector(state => state.analyticsReducer.returnedData);
  const mapShapes = useSelector(state => state.analyticsReducer.keylinesConfig.mapShapes);

  useEffect(() => {
    /*
    Geofilter data returned from API call based on the shape geo-filters.
    Should trigger when returnedData or shapes in redux store change.
    */
    if (mapShapes) {
      // If no shapes recorded, return all data.
      if (Object.keys(mapShapes).length > 0) {
        // Get array of keylines format links and nodes within the mapShapes
        const items = filterByMapShapes(mapShapes,returnedData)
        // Important to set chartLoaded to false to trigger effects that manage the map
        // and data will wait for when the react-keylines component sets chartLoaded back to true.
        // This is because updating the filtered data causes keylines to call chart.load which
        // means the effects that wrangle keylines into map mode and cluster modes etc need to
        // called again.
        setChartLoadedAction(dispatch,false);
        updateFilteredDataAction(dispatch,{
          type: 'LinkChart',
          items: items,
        })
      } else {
        setChartLoadedAction(dispatch,false);
        updateFilteredDataAction(dispatch,returnedData)
      }
    } else {
      setChartLoadedAction(dispatch,false);
      updateFilteredDataAction(dispatch,returnedData)
    }
  }, [returnedData,mapShapes,dispatch])

  return <React.Fragment></React.Fragment>
}

export default ShapeFilteringComponent;