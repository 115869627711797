/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

export const inPolygon = (point, vertices) => {
    /*
    Ray casting algorithm. Sends out a line from the test point and checks for
    intersections with polygon edge. If there is an odd number of intersections,
    the test point must be inside the polygon.
    */

    var x = point.lng, y = point.lat;
    
    var inside = false;
    for (var i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
        var xi = vertices[i].lng, yi = vertices[i].lat;
        var xj = vertices[j].lng, yj = vertices[j].lat;
        
        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};