/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import InputIcon from '@material-ui/icons/Input';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AnalyticsView from './views/AnalyticsPage';
import LoginView from './views/LoginPage';

export const routes = [
  { path: '/analytics',
  name: 'Analytics',
  component: AnalyticsView,
  icon: InputIcon,
  privileged: true},
  { path: '/login',
    name: 'Login',
    component: LoginView,
    icon: PersonAddIcon,
    privileged: false,}
];