/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import {
    createStore, combineReducers, applyMiddleware 
  } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork, all } from 'redux-saga/effects'
import { composeWithDevTools } from "redux-devtools-extension";

import analyticsReducer from '../../views/AnalyticsPage/state/analyticsReducer';
import loginReducer from '../../views/LoginPage/state/loginReducer';
import userReducer from './user/userReducer';

import analyticsSagas from '../../views/AnalyticsPage/state/analyticsSagas';
import loginSagas from '../../views/LoginPage/state/loginSagas';
import userSagas from './user/userSagas';

const intialState = {};

const sagaMiddleware = createSagaMiddleware();
function* rootSaga() {
    yield all([
        fork(analyticsSagas),
        fork(loginSagas),
        fork(userSagas),
    ]);
}
// combine all reducers into one which speaks to the store
export const rootReducer = combineReducers({
    analyticsReducer: analyticsReducer,
    loginReducer: loginReducer,
    userReducer: userReducer,
});

const store = createStore(
    rootReducer,
    intialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
    );

sagaMiddleware.run(rootSaga);

export default store;