/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { IconButton, Menu, Tooltip } from '@material-ui/core';
import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { setCombineByAction } from '../../../state/analyticsActions';
import combineFields from '../../../../../common/config/combineFields';
import PropTypes from 'prop-types';


const ClusterMenu = ( { containerRef } ) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const currentCombineBy = useSelector(state => state.analyticsReducer.keylinesConfig.combineBy);

    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        };
    const open = Boolean(anchorEl)

    const changeCombine = (field) => { 
        setCombineByAction(dispatch,field)
    };
    const handleItemClick = (event,newCombineBy) => {
        if (newCombineBy !== null) {
            changeCombine(newCombineBy);
        }
    };
    return <div>
            <Tooltip
            PopperProps={{
                container: containerRef.current
            }}
            title='Cluster Options' 
            color='primary'>
                <IconButton onClick={handleClick}>
                    <BubbleChartIcon />
                </IconButton>
            </Tooltip>
        <Menu
        // this is important to avoid adding padding to body div
        container={containerRef.current}
        disableScrollLock={true}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={open}
        onClose={handleClose}>
            <ToggleButtonGroup
            value={currentCombineBy}
            exclusive
            orientation='vertical'
            onChange={handleItemClick}
            >
                {Object.values(combineFields).map(field => {
                return <ToggleButton key={field} value={field}>
                    {field}
                </ToggleButton>
            })}
            </ToggleButtonGroup>
        </Menu>
    </div>
}
ClusterMenu.propTypes = {
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }
export default ClusterMenu;