/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

/* eslint-disable no-undef */

var config = {};

config.PUBLIC_URL = process.env.PUBLIC_URL
config.STAGE = process.env.REACT_APP_STAGE;

export default config;
