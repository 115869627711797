/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import {  TableRow, TableCell, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { useState } from 'react';
import clsx from 'clsx';
import { makeList } from './BankDetailsTable';
import PropTypes from 'prop-types';




const ExpandableTableEntry = ({AdditionalObject,cellClass,evenRowClass, name}) => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow key={name} className={evenRowClass} >
                <TableCell align="left" component="th" scope="row" className={clsx(cellClass)}>
                        {name}
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </ TableRow>
                <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    makeList(AdditionalObject,cellClass,evenRowClass)
                }
            </Collapse>

        </React.Fragment>
    );
};
ExpandableTableEntry.propTypes = {
    AdditionalObject: PropTypes.object,
    name: PropTypes.string,
    cellClass: PropTypes.string,
    evenRowClass: PropTypes.string,
  }

export default ExpandableTableEntry;
