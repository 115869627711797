/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as Actions from './analyticsActionTypes';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import * as defaultBankDetails from '../../../common/config/defaultBankDetails';
import { retrieveBankDetailsAction, setContextMenuAction } from './analyticsActions';
import { awsmobile } from '../../../index.js';
import printPDF from '../../../common/utils/functions/download/printPDF';
import jsPDF from 'jspdf'
import domtoimage from 'dom-to-image';
import combineFields from '../../../common/config/combineFields';
import { subgroups, bankDetailsGroups } from '../../../common/config/defaultBankDetails';
import { SelectedIcon } from '../../../common/config/keylinesMapConfig'

//const delay = (ms) => new Promise(res => setTimeout(res, ms))
export const getKeylineDataState = (state) => state.analyticsReducer.returnedData;
export const getResultTableState = (state) => state.analyticsReducer.keylinesForegroundData;
export const getBankDetailsState = state => state.analyticsReducer.selectedBankDetails.details;
export const getMapOn = state => state.analyticsReducer.keylinesConfig.mapVisible;
export const getCombineByState = (state) => state.analyticsReducer.keylinesConfig.combineBy;
export const getCurrentSession = (state) => state.userReducer.userSession;
export const getSelectedId = (state) => state.analyticsReducer.selectedBankDetails.bankId;


var markers = L.markerClusterGroup({
    animate: false,
    chunkedLoading: true,
});

var selectedMarkerGroup = L.featureGroup();

const getKeylinesData = (state) => state.analyticsReducer.keylinesData;
const getSearchTerm = (state) => state.analyticsReducer.search.value;
const getFilterTerm = (state) => state.analyticsReducer.search.filter;
const getNodesIndexArray = (state) => state.analyticsReducer.nodesIndexArray;

const nodeDefaults = {
    type: 'node',
    u: 'icons/bank2.png',
    fs: 24,
    c: '#c1d5e0',
    e: 0.75,
};

function luceneSearchAPICall(payload, token) {
    const uri = awsmobile["api_host"] + ":" +
                awsmobile["api_port"] +
                awsmobile["api_prefix"] + "/searches"
    return fetch(uri, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload)
    })
}

function retrieveNeighboursApiCall(LocationId, token) {
    const uri = awsmobile["api_host"] + ":" +
                awsmobile["api_port"] +
                awsmobile["api_prefix"] + "/neighbors/" +
                LocationId
    return fetch(uri, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })
}

function retrieveDetailsApiCall(LocationId, token) {
    const uri = awsmobile["api_host"] + ":" +
                awsmobile["api_port"] +
                awsmobile["api_prefix"] + "/details/" +
                LocationId
    return fetch(uri, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })
}

function formatCSVTableData(tableEntries) {
    let dataArray = [];
    for(let i =0 ; i< tableEntries.length ;i++){
        const parent = tableEntries[i];
        const details = parent['d'];
        dataArray.push(details);
    }
    return dataArray;
}

function isValidInput(searchTerm) {
    let successful = true;

    successful = searchTerm && successful;
    successful = searchTerm.trim() && successful;

    return successful;
}

function* exportTableData(action) {
    yield put({type: Actions.EXPORT_TABLE_PENDING})

    const tableData = yield select(getResultTableState);

    if(action.fileType === ".pdf"){
        const response = yield call(getTablePDF, action.fileName);
        if(response){
            //No need to pass any data to export component
            put({type: Actions.CLEAR_TABLE_DOWNLOAD_STATE});
        }else{
            yield put({ type:Actions.EXPORT_TABLE_FAILURE, error:'Failed to export data'})
        }
    }else{
        const response = yield call(formatCSVTableData, tableData.items);
        if(response){
            yield put({ type: Actions.EXPORT_TABLE_SUCCESS, data:response})
        }else{
            yield put({ type:Actions.EXPORT_TABLE_FAILURE, error:'Failed to export data'})
        }
    }
}
function getTablePDF(fileName){
    return printPDF(fileName,"resultsTable",'l',.2);
}  
function getBankPDF(fileName){
    return printPDF(fileName,"detailsContainer",null,.46);
}  

function* exportBankDetailsData(action){
    yield put({type: Actions.EXPORT_TABLE_PENDING, fileType: action.fileType, fileName: action.fileName })
    const bankData = yield select(getBankDetailsState);

    if(action.fileType === ".pdf"){
        const response = yield call(getBankPDF, action.fileName);
        if(response){
            //No need to pass any data to export component
            put({type: Actions.CLEAR_TABLE_DOWNLOAD_STATE});
        }else{
            yield put({ type:Actions.EXPORT_TABLE_FAILURE, error:'Failed to export data'})
        }
    }else{
        const response = yield call(formatCSVBankData, bankData, action.extras);
        if(response && response.length > 0){
            yield put({ type: Actions.EXPORT_TABLE_SUCCESS, data:response})
        }else{
            yield put({ type:Actions.EXPORT_TABLE_FAILURE, error:'Failed to export data'})
        }
    }
}
function formatCSVBankData(bankData, extras) {
    let dataArray = [];
    Object.keys(bankData).map((key) => {
        if (bankData[key].group === defaultBankDetails.keysArray[extras].name) {
            let field = null;
            let value = null;
            if (bankData[key].subgroup === subgroups.additionalInfoGroup) {
                if (bankData[key].value !== null) {
                    if (Object.keys(bankData[key].value).length !== 0) {
                        let nestedMap = bankData[key].value
                        for (let [key, mapValue] of Object.entries(nestedMap)) {
                            field = key
                            value = mapValue
                            let formattedNestedObject = {field, value}
                            dataArray.push(formattedNestedObject)
                        }
                    }
                }
            } else if (bankData[key].group === bankDetailsGroups.boardMembersGroup.name) {
                if (bankData[key].value !== null) {
                    for (let i = 0; i < bankData[key].value.length; i++) {
                        field =  bankData[key].name;
                        value = bankData[key].value[i]
                        let formattedOfficers = {field, value}
                        dataArray.push(formattedOfficers)
                    }
                }
            } else {
                field =  bankData[key].name;
                value = bankData[key].value;
                let formattedObject = {field, value}
                if (field !== null && value !== null) {
                    dataArray.push(formattedObject);
                }
            }
        }
        return null;
    });
    return dataArray;
}

// retrieve individual bank details in response to node click
function* retrieveBankDetails(action) {
    try {
        yield put({
            type: Actions.SET_SELECTED_BANK_ID,
            bankId: action.bankId})
    
        const session = yield select(getCurrentSession);
        const token = session.accessToken.jwtToken;
        const response = yield call (retrieveDetailsApiCall, action.bankId,token)
        const data = yield response.json()
        const selectedBankDetails = data.details
        // do some preprocessing to get info into shape that we want before putting in redux
        let formattedBankDetails = defaultBankDetails.defaultBankDetails;
        Object.keys(formattedBankDetails).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(selectedBankDetails, key)) {
                formattedBankDetails[key] = {
                    ...formattedBankDetails[key],
                    value: selectedBankDetails[key]
                }
            }
        })
        yield put({
            type: Actions.SET_SELECTED_BANK_DETAILS,
            bankDetails: formattedBankDetails,
        })
        yield put({type: Actions.API_DETAILS_SUCCESSFUL})

    } catch (error) {
        yield put({type: Actions.API_DETAILS_FAILED,
            error: "Bank Details API request has failed."})
        console.log("error occurred in saga: ", error)
    }
}

const createAndDownloadPDForPNG = (fileName,fileType,image) => {
    if(fileType === '.pdf'){
        const pdf = new jsPDF('l');
        const imgProps= pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(image, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(fileName);
    }else{
        var a = document.createElement("a"); //Create <a>
        a.href = image; //Image Base64 Goes here
        a.download = fileName; //File name Here
        a.click(); //Downloaded file
    }
}

function* downloadImage(fileName, fileType, chart){
    const combineByState = yield select(getCombineByState);
    if(combineByState !== combineFields.correspondent){
        const container = document.getElementById("chart");
        try {
            const image = yield domtoimage.toPng(container)
            createAndDownloadPDForPNG(fileName,fileType,image);
        } catch (err) {
            console.error(err);
        }
    }else{
        const chartSize = yield select(state => state.analyticsReducer.chartSize);
        // this choice will either be 'exact' or 'oneToOne'
        const choice = 'exact'
        // use this scale to draw the chart larger or smaller (just for fit: 'exact', 'view' or 'chart',
        // because the oneToOne setting the width and height are ignored)
        const scale = 3;
        try {
            const image = yield chart.toDataURL(chartSize.width * scale, chartSize.height * scale, {
                selection: true,
                fit: choice,
            });
            createAndDownloadPDForPNG(fileName,fileType,image);
        }
        catch (err) {
            console.error(err)
        }
    }
}
function* downloadKeylinesImage(action) {
    const response = yield call(downloadImage, action.fileName, action.fileType, action.data);
    if(response){
        yield put({ type:Actions.EXPORT_TABLE_FAILURE, error:'Failed to export data'})
    }
}

function* retrieveBankDetailsSaga() {
    yield takeLatest(Actions.RETRIEVE_BANK_DETAILS, retrieveBankDetails);
}
function* exportTableDataSaga(){
    yield takeLatest(Actions.EXPORT_TABLE_DATA, exportTableData);
}
function* exportBankDetailsSaga(){
    yield takeLatest(Actions.EXPORT_BANK_DETAILS_DATA, exportBankDetailsData)
}
function* downloadKeylineImageSaga(){
    yield takeLatest(Actions.DOWNLOAD_KEYLINES_IMAGE, downloadKeylinesImage)
}

function* hideMap(action) {
    if (action.ref !== null) {
        if (action.ref.map && action.mapSetter) {
            yield action.ref.map().hide();
            action.mapSetter(null);
        }
    }
}
function* hideMapSaga() {
    yield takeLatest(Actions.HIDE_MAP, hideMap)
}

const checkForLeafletMapLoop = (chart,mapSetter) => {
    try {
        chart.map().show();
        if (chart.map().leafletMap()) {
            mapSetter(chart.map().leafletMap());
        } else {
            setTimeout(() => checkForLeafletMapLoop(chart,mapSetter), 300);
        }
    } catch (err) {
        console.log('Error showing the map')
        console.log(err)
    }
    
}

function* showMap(action) {
    if (action.ref !== null) {
        if (action.ref.map && action.mapSetter) {
            checkForLeafletMapLoop(action.ref,action.mapSetter)
        }
        yield put({type: Actions.MAP_TOGGLE_COMPLETE, payload: true})
    }
}
function* showMapSaga() {
    yield takeLatest(Actions.SHOW_MAP, showMap)
}

function* addLeafletClusters(action) {
    const chart = action.chart;
    const dispatch = action.dispatch;
    const data = yield select(getKeylinesData);
    const selectedId = yield select(getSelectedId);

    // Some nasty imperative stuff going on here, can maybe retrieve markers from 
    // a reducer but might make calling methods like clearLayers weird. Markers is instead
    // defined at top of file, classic javascript way...
    // Works for now anyway
    markers.clearLayers();
    selectedMarkerGroup.clearLayers();
    if (data.items.length > 0) {

        // Create map panes to preserve order of layers
        chart.map().leafletMap().createPane('selectedMarker');
        chart.map().leafletMap().getPane('selectedMarker').style.zIndex = 2000;

        // generate markers, and hide links and nodes

        var markerList = [];
        var knownIds = [];
        var unknownIds = [];
        data.items.forEach(item => {
            if (item.type === 'node') {
                if (item.pos.lat !== 'unknown') {
                    knownIds.push(item.id);
                    if(selectedId === item.id){
                        const marker = L.marker(L.latLng(item.pos.lat,item.pos.lng), {icon:SelectedIcon , title: item.t, keylinesId: item.id, pane: 'selectedMarker'});
                        selectedMarkerGroup.addLayer(marker);
                    }else{
                        const marker = L.marker(L.latLng(item.pos.lat,item.pos.lng), {title: item.t, keylinesId: item.id});
                        markerList.push(marker);
                    }
                } else {
                    unknownIds.push(item.id);
                }
            }
        })

        yield chart.hide([...knownIds,...unknownIds]);

        if (knownIds.length > 0) {
            markers.addLayers(markerList);

            // Check that marker events are only added once
            if (!markers._events) {
                // tie clicking of marker to bank details drawer
                markers.on('click', function (a) {
                    if (a.originalEvent.button === 0) {
                        const id = a.propagatedFrom.options.keylinesId;
                        retrieveBankDetailsAction(dispatch,id);
                    }
                });
                markers.on('contextmenu', function (a) {
                    const point = a.containerPoint;
                    const id = a.propagatedFrom.options.keylinesId;
                    const title = a.propagatedFrom.options.title;
                    setContextMenuAction(dispatch,point.x,point.y,'node',id, title);

                });
            }
            
            if (!selectedMarkerGroup._events) {
                selectedMarkerGroup.on('contextmenu', function (a) {
                    const point = a.containerPoint;
                    const id = a.propagatedFrom.options.keylinesId;
                    const title = a.propagatedFrom.options.title;
                    setContextMenuAction(dispatch,point.x,point.y,'node',id, title);
        
                });
            }
            
            chart.map().leafletMap().addLayer(markers)
            chart.map().leafletMap().addLayer(selectedMarkerGroup)
            action.setMarkersPresent(true);
        }
    }
  }

function* addLeafletClustersSaga() {
    yield takeLatest(Actions.ADD_CLUSTERS, addLeafletClusters)
}

function* removeLeafletClustersAndShowChart(action) {
    const chart = action.chart;
    const data = yield select(getKeylinesData);
    const mapOn = yield select(getMapOn);
    // remove markers from markers layer group
    markers.clearLayers();
    selectedMarkerGroup.clearLayers();
    // reveal chart items (nodes and links)
    var ids = [];
    // Need to add a check for when the unknowns have been excluded and report it to
    // chart component. Otherwise, when transitioning from map None view to network,
    // "unknowns" will not be added back in
    if (typeof chart.show === 'function') {
        data.items.forEach(item => {
            if (mapOn) {
                // if the map is on, exclude uknown locations, include all links possible
                if (item.type === 'node'
                && item.pos.lat !== 'unknown') {
                    ids.push(item.id);
                } else if (item.type === 'link') {
                    ids.push(item.id);
                }
            } else {
                ids.push(item.id);
            }
        })
    }
    yield chart.show(ids);
    action.setMarkersPresent(false);
  }

function* removeLeafletClustersAndShowChartSaga() {
    yield takeLatest(Actions.REMOVE_CLUSTERS, removeLeafletClustersAndShowChart)
}

function* neo4jLuceneSearchSaga() {
    yield takeLatest(Actions.LUCENE_SEARCH,neo4jLuceneSearch)
}

function* neo4jLuceneSearch() {
    try {

        const searchTerm = yield select(getSearchTerm);
        const filterTerm = yield select(getFilterTerm);
        const session = yield select(getCurrentSession);
        const token = session.accessToken.jwtToken;

        if ( !isValidInput(searchTerm) ){
            return;
        }
        // Clear selected bank state so that keylines doesn't try to highlight
        // a possibly non-existent node
        yield put({type: Actions.CLEAR_SELECTED_BANK_DETAILS});

        // add whitespce to end of search string to fix bug caused by
        // the backslash being the final character in searchTerm
        const searchTermPadded = searchTerm.concat(" ") 
        let nodeIndices = [];
        const searchBody = {"search": searchTermPadded, "filter": filterTerm, "query_type": "cypher"};
        const response = yield call(luceneSearchAPICall, searchBody,token);
        const data = yield response.json()

        let dataKeylinesList = {}
        dataKeylinesList['type'] = "LinkChart"
        dataKeylinesList['items'] = []
        data.response_list.forEach(function (item){
            // prevent entries with duplicate ids
            if (!nodeIndices.includes(item.id)) {
                const glyph = {
                    g: [
                        {
                            c: "rgb(10,42,59)",
                            p: 'ne',
                            t: String(item.d.degree),
                            e: 2,
                        }
                    ]
                }
                const dataKeylines = Object.assign({},nodeDefaults,item,glyph)
                dataKeylinesList["items"].push(dataKeylines)
                nodeIndices.push(item.id)
            }
        });

        yield put({
            type: Actions.REPLACE_DATA,
            payload: dataKeylinesList,
            nodesIndexArray: nodeIndices
        })
        yield put({type: Actions.API_SEARCH_SUCCESSFUL})


    
    } catch (error) {
        yield put({type: Actions.API_SEARCH_FAILED,
             error: "Search request has failed."})
        console.log("error occurred in saga: ", error)
    }
}

function* retrieveNeighboursSaga() {
    yield takeLatest(Actions.RETRIEVE_NEIGHBOURS_BY_ID,retrieveNeighbours)
}

function* retrieveNeighbours(action) {
    try {
        const nodeIndices = yield select(getNodesIndexArray);
        let nodeIndicesCopy = [...nodeIndices];
        const mapOn = yield select(getMapOn);
        // Make sure bank details are cleared to avoid zooming on a faulty/loading map instance
        if (mapOn) {
            yield put({type: Actions.CLEAR_SELECTED_BANK_DETAILS});
        } else {
            yield put({
                type: Actions.SET_SELECTED_BANK_ID,
                bankId: action.bankId})
        }
        const session = yield select(getCurrentSession);
        const token = session.accessToken.jwtToken;
        const response = yield call(retrieveNeighboursApiCall, action.bankId,token)
        const data = yield response.json()

        var dataKeylinesList = [];
        data.response_list.forEach(function (item){
            if (item.type === 'node') {
                if (!nodeIndicesCopy.includes(item.id)) {
                    const glyph = {
                        g: [
                            {
                                c: "rgb(10,42,59)",
                                p: 'ne',
                                t: String(item.d.degree),
                                e: 2,
                            }
                        ]
                    }
                    const chartItem = Object.assign({},nodeDefaults,item,glyph)
                    dataKeylinesList.push(chartItem)
                    nodeIndicesCopy.push(item.id)
                }
            } else if (item.type === 'link') {
                dataKeylinesList.push(item)
            }
        });

        yield put({
            type: Actions.APPEND_DATA,
            payload: dataKeylinesList,
            nodesIndexArray: nodeIndicesCopy
        })
        if (data.exceeded_limit) {
            yield put({
                type: Actions.API_NEIGHBORS_EXCEEDED,
                warning: "Warning: the number of neighbors for this bank exceeds what can be displayed, displaying the first "+data.response_list.length / 2+ " results."
            })
        }
        yield put({type: Actions.API_NEIGHBORS_SUCCESSFUL})

    } catch (error) {
        yield put({type: Actions.API_NEIGHBORS_FAILED,
            error: "Bank neighbors API call has failed."})
        console.log("error occurred in saga: ", error)
   }
}



export default function* AnalyticsSagas() {
    yield all([
        retrieveBankDetailsSaga(),
        hideMapSaga(),
        showMapSaga(),
        addLeafletClustersSaga(),
        exportTableDataSaga(),
        exportBankDetailsSaga(),
        neo4jLuceneSearchSaga(),
        retrieveNeighboursSaga(),
        downloadKeylineImageSaga(),
        removeLeafletClustersAndShowChartSaga(),
    ]);
}

