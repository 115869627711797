/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react'
import { CSVLink } from 'react-csv'
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types';

const AutoCSVDownload = ({
    data,
    headers,
    filename,
    clearFunction
}) => {
    /* Component that begins a csv download when rendered for the first time
    be careful not to trigger re-renders!
    data - array of objects to be translated into csv
    e.g. data = [{name: 'Larry', age: 5},{name: 'Harold', age: 76}]
    headers - strings to become headers in the csv, typically the keys of the array
    you provide as data. e.g ['name','age']
    children - React components to be rendered within the link
    */

    const dispatch = useDispatch()
    const exportButton = React.useRef();
    const [linkReady, setLinkReady] = React.useState(false);
        
    React.useEffect(() => {
        if (exportButton.current !== undefined) {
            if(exportButton.current.link
                && exportButton.current.link.click
                && typeof exportButton.current.link.click == 'function'
            ) {
                setLinkReady(true);
            }
        }
    }, [exportButton])
    React.useEffect(() => {
        if (exportButton.current !== undefined) {
            if(exportButton.current.link
                && exportButton.current.link.click
                && typeof exportButton.current.link.click == 'function'
            ) {
                if (linkReady) {
                    exportButton.current.link.click();
                    clearFunction(dispatch);
                }
            }
        }
    }, [exportButton,linkReady, dispatch, clearFunction])
    return (
        <CSVLink
        id="autoDownload"
        data={data}
        role='button'
        target='_blank'
        headers={headers}
        filename={filename}
        ref={exportButton}>
        </CSVLink>
    );
}
AutoCSVDownload.propTypes = {
    data: PropTypes.array,
    headers: PropTypes.array,
    filename: PropTypes.string,
    clearFunction: PropTypes.func,
}
export default AutoCSVDownload