/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


const combineFields = {
    correspondent: 'Correspondent',
    proximity: 'Proximity',
}

export default combineFields;