/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import useFullscreenStatus from "../../utils/hooks/useFullScreenStatus";
import PropTypes from 'prop-types';

const FullscreenButton = ( { containerRef } ) => {
  let isFullscreen, setFullscreen, exitFullscreen;

  // Instantiate custom hook for fullscreen status
  try {
    [isFullscreen, setFullscreen, exitFullscreen] = useFullscreenStatus(containerRef);
  } catch (e) {
    isFullscreen = false;
    setFullscreen = undefined;
    exitFullscreen = undefined;
  }

  return (
    isFullscreen
    ? <Tooltip
      PopperProps={{
        container: containerRef ? containerRef.current : null
      }}
      title='Exit fullscreen'>
        <IconButton onClick={exitFullscreen}>
          <FullscreenExitIcon />
        </IconButton>
      </Tooltip>
    : <Tooltip 
      title='Fullscreen'
      PopperProps={{
        container: containerRef ? containerRef.current : null
      }}>
      <IconButton onClick={setFullscreen}>
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  );
};
FullscreenButton.propTypes = {
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};
export default FullscreenButton;