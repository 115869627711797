/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ExportComponent from './ExportComponent';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


export const DownloadDialog = ({
  dialogOpen,
  setDialogOpen,
  fileTypeOptions,
  targetVisualization,
  dataToExport,
  successSelector,
  extras,
  containerRef
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [fileType, setFileType] = React.useState(0);
  const [fileName, setFileName] = React.useState('');
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const handleDownload = () => {
    fileTypeOptions.downloadFunction(dispatch, fileType, fileName, dataToExport, extras)
    handleDialogClose();

  };

  const handleTextFieldChange= (event) => {
    setFileName(event.target.value)
  }
  const handleChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    }

  useEffect(() => {
      if (fileType !== 0 && fileName !== '') {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    },[fileType, fileName])

    return (
        <Dialog
        container={containerRef ? containerRef.current : null}
        open={dialogOpen}
        onClose={handleDialogClose}
        style={{textAlign:"center"}}
        overlayStyle={{backgroundColor: 'white'}}>
            <DialogTitle>
              <Typography variant="h4">
                Download {targetVisualization}
              </Typography>
            </DialogTitle>
            <DialogContent style={{minHeight:"40vh"}}>
              <Typography>
                Choose a name for your file
              </Typography>
              <br />
              <TextField
              required
              className={classes.formControl}
              id="outlined-secondary"
              label="File name"
              value={fileName}
              onChange={handleTextFieldChange}
              variant="outlined"
              color="secondary"
              />
              <FormControl required
              className={classes.formControl}
              variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={fileType}
                  MenuProps={{container: containerRef ? containerRef.current : null}}
                  onChange={handleChange}
                  label="File type">
                  {
                    fileTypeOptions.names &&
                      fileTypeOptions.names.map(item => {
                        return (
                          <MenuItem
                          value={item}
                          key={item}
                          onClick={handleChange}>
                            <Typography variant="body1">{item}</Typography>
                          </MenuItem>
                        )
                      })
                  }
                </Select>
                <FormHelperText>Choose a file type</FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
              disabled={buttonDisabled}
              variant='contained'
              onClick={handleDownload}
              color="primary"
              autoFocus>
              Download
              </Button>
              <Button
              variant='contained'
              onClick={handleDialogClose}
              color="primary"
              autoFocus>
              Close
              </Button>
            </DialogActions>
            {successSelector && fileType !== '.pdf' && <ExportComponent
              success={successSelector}
              data={dataToExport}
              fileName={fileName}
              fileType={fileType}
              clearFunction={fileTypeOptions.downloadClearAction}/>}
        </Dialog>
    )
}
DownloadDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  fileTypeOptions : PropTypes.object,
  targetVisualization: PropTypes.string,
  dataToExport: PropTypes.oneOfType([
    // Data array
    PropTypes.array, 
    // Or the chart object
    PropTypes.object]),
  successSelector: PropTypes.bool,
  extras: PropTypes.number,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}