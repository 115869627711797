/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearContextMenuAction, retrieveNeighboursAction, singleOutNodeAction } from '../../state/analyticsActions';
import PropTypes from 'prop-types';
import { ListItem, Menu, MenuItem, Typography } from '@material-ui/core';

const PopupMenu = ( { chartBounds, containerRef } ) => {
  const menuCoords = useSelector(state => state.analyticsReducer.keylinesConfig.contextMenu.coords);
  const menuKeylinesId = useSelector(state => state.analyticsReducer.keylinesConfig.contextMenu.id);
  const menuTitle = useSelector(state => state.analyticsReducer.keylinesConfig.contextMenu.title);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    clearContextMenuAction(dispatch);
  }, [dispatch]);

  const excludeOtherNodes = useCallback(() => {
      singleOutNodeAction(dispatch,menuKeylinesId);
      handleClose();
  },[handleClose, dispatch, menuKeylinesId]);

  const retrieveNeighbours = useCallback(() => {
    retrieveNeighboursAction(dispatch,menuKeylinesId);
    handleClose()
  },[dispatch,handleClose,menuKeylinesId])

  // If chart bounds change, close popup menu
  useEffect(() => {
    handleClose(dispatch)
  }, [dispatch,handleClose,chartBounds])
 
  return (
        <Menu
        container={containerRef.current}
        keepMounted
        open={menuCoords.y !== null}
        anchorReference="anchorPosition"
        onClose={handleClose}
        style={{zIndex: 1000}}
        anchorPosition={
          menuCoords.y !== null && menuCoords.x !== null
            ? { top: menuCoords.y + chartBounds.top, left: menuCoords.x + chartBounds.left}
            : undefined}
        >
            <ListItem>
                <Typography variant='subtitle1' color='primary'>{menuTitle}
                </Typography>
            </ListItem>
            <MenuItem
            onClick={excludeOtherNodes}>
                Remove All Other Nodes
            </MenuItem>
            <MenuItem
            onClick={retrieveNeighbours}>
                Retrieve Neighbours
            </MenuItem>
        </Menu>
  )
}
PopupMenu.propTypes = {
  chartBounds: PropTypes.object,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

export default PopupMenu;