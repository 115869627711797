/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import jsPDF from 'jspdf'

export default function printPDF(fileName,elementId,jsPDFOptions,scale) {
    const input = document.getElementById(elementId);
    const pdf = new jsPDF(jsPDFOptions);
    pdf.html(input, { 
        jspdf: { compressPdf:true },
        html2canvas: { async:true, scale: scale, } 
    })
        .then(() => {
    pdf.save(fileName);
   });
  } 