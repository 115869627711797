/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';


export const ErrorAlert = ({
    selector,
    style,
}) => {
    const formErrorMessage = useSelector(selector)
    return (
        formErrorMessage &&
        <Alert className={style} severity="error">
            {formErrorMessage}
        </Alert>
    )
}
ErrorAlert.propTypes = {
    selector: PropTypes.func, 
    style: PropTypes.string,
}