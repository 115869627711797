/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { Toolbar, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    tabsHolder: {
        display: 'flex',
        justifyContent: 'space-between'
    },
}))

/*
Render icon buttons in a toolbar, one for each group
Selected icon has primary colour, others have secondary
*/

const BankDetailsNav = ({
    keysArray,
    activeStep,
    setActiveStep
}) => {
    const classes = useStyles();
    
    return (
        <Toolbar 
        variant='dense'
        className={classes.tabsHolder}>
        {keysArray.map((group,index) => {
            return (
                < Tooltip 
                title={group.name} 
                key={group.name}
                color='primary' 
                placement='bottom'>
                    <IconButton 
                    color={Number(activeStep) === Number(index) 
                        ? 'primary' 
                        : 'secondary'}
                    onClick={() => setActiveStep(index)}>
                        <group.icon 
                        color={Number(activeStep) === Number(index) 
                            ? 'primary' 
                            : 'secondary'}/>
                    </IconButton>
                </Tooltip>)})}
        </Toolbar>
      );
}
BankDetailsNav.propTypes = {
    keysArray: PropTypes.array,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
  }

export default BankDetailsNav;