/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as userActions from './userActionTypes';
import * as loginActions from '../../../views/LoginPage/state/loginActionTypes';

const initialState = {
    user: null,
    userSession: null
}

export default function authReducer(state = initialState, action) {

    switch (action.type) {
  
      case userActions.CHECK_USER_SUCCEEDED:
        return {
            ...state,
            user: action.user,
        }
      case userActions.CHECK_USER_FAILED:
        return {
            ...state,
            user: initialState.user,
            userSession: initialState.userSession,
        }
      case userActions.USER_SIGN_OUT:
        return initialState;
      case loginActions.NEW_PASSWORD_REQUIRED:
          return {
              ...state,
              user: action.user,
          }
      case userActions.USER_REFRESH_TOKEN:
        return {
          ...state,
          userSession: {...action.userSession},
        }
      default:
        return state;
    }
  }