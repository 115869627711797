/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  image: {
    verticalAlign: "middle",
    width: "auto",
    maxHeight: 48,
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    // make logo white
    
  }
}));
const AppbarLogo = () => {
  const classes = useStyles();
  return (
      <img className={classes.image}
        src={'/illuminate.png'}
        alt="Illuminate Logo"
        id="HomeLogo"
      />
  );
};

export default AppbarLogo;