/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import Amplify from 'aws-amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './common/state/configureStore';
import config from './common/config/processFile';

const envSwitch = {
  dev: {
    "aws_user_pools_id": "us-east-1_N9jdbxGGh",
    "aws_user_pools_web_client_id": "7d6tfs11npuhju3lbitq3he86r",
    "api_host": "http://127.0.0.1",
    "api_port": "5000",
    "api_prefix": "/ransack/api/v1"
  },
  stage: {
    "aws_user_pools_id": "us-east-1_iiX3Vsbu7",
    "aws_user_pools_web_client_id": "24inhmlsfrushmbrasqln40s7n",
    "api_host": "https://server.ransackdemo.com",
    "api_port": "5000",
    "api_prefix": "/ransack/api/v1"
  },
  production: {
    "aws_user_pools_id": "us-east-1_HYJdrwfzV",
    "aws_user_pools_web_client_id": "8d2vst7u9prqbelu51h93400b",},
};

const envConfig = envSwitch[config.STAGE];

export const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "oauth": {},
  ...envConfig
}

Amplify.configure(awsmobile)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
