/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { CssBaseline } from '@material-ui/core';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import LoginPage from './components/LoginPanel/LoginPage';
import RansackLandingInfo from './components/RansackInfo/RansackLandingInfo';
import PropTypes from 'prop-types';
import config from '../../common/config/processFile';

const useStyles = makeStyles((theme) => ({
    globe: {
        position: 'absolute',
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        overflow: "visible",
        // if width greater than height
        '@media (min-aspect-ratio: 1/1)': {
            width: "100vw",
            height: "100vw"
        },
        '@media (max-aspect-ratio: 1/1)': {
            width: "100vh",
            height: "100vh"
        },
        opacity: 0.9,
        zIndex: 1,
    },
    overlay: {
        position: 'absolute',
        top: "50%",
        transform: "translate(0,-50%)",
        zIndex: 2,
        width: "100%",
        alignItems: 'center',
        height: `calc(
            100vh
            - ${2*theme.overrides.ClassificationBanner.height}px)`,
    },
    globeCanvasHolder: {
        height: `calc(
            100vh
            - ${2*theme.overrides.ClassificationBanner.height}px)`,
    },
    LoginPanel: theme.overrides.LoginFormRoot,
    RansackInfoDiv: {
        display: "flex",
        position: "absolute",
        flexDirection: 'column',
        width: "100%",
        maxWidth: `calc(100vw - ${2*theme.overrides.LoginFormRoot.maxWidth}px)`,
        height: "100%",
        textAlign: 'center',
        top: "0",
        left:0,
        right: 0,
        margin: "0 auto"
    },
    RansackTextHolder: {
        margin: 'auto 0',
        display: "flex",
        maxHeight: "70%",
        minHeight: "50vh",
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}))
const LoginView = ({path}) => {
    const classes = useStyles();
    useEffect(() => {
        const script = document.createElement('script')
        script.src = config.PUBLIC_URL + 'assets/js/globe.js'
        script.async = true
        script.type = 'text/javascript'
        document.body.appendChild(script)
      }, [])
    return <React.Fragment>
        <CssBaseline />
        <div className={classes.globeCanvasHolder}>
            <div className={clsx("js-globe",classes.globe)} />
        </div>
        <div className={classes.overlay}>
            <div className={classes.LoginPanel}>
                <LoginPage path={path}/>
            </div>
            <div className={classes.RansackInfoDiv}>
                <div className={classes.RansackTextHolder}>
                    <RansackLandingInfo />
                </div>
            </div>
        </div>
    </React.Fragment>
}
LoginView.propTypes = {
    path: PropTypes.string,
  }

export default LoginView;