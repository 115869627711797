/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


export const TOGGLE_BANK_DETAILS_DRAWER = 'TOGGLE_BANK_DETAILS_DRAWER';
export const RETRIEVE_BANK_DETAILS = 'RETRIEVE_BANK_DETAILS';
export const SET_SELECTED_BANK_ID = 'SET_SELECTED_BANK_ID';
export const SET_SELECTED_BANK_DETAILS = 'SET_SELECTED_BANK_DETAILS';
export const CLEAR_SELECTED_BANK_DETAILS = 'CLEAR_SELECTED_BANK_DETAILS';

/////////////////////////
// MAP CONFIG
/////////////////////////
export const MAP_TOGGLE_COMPLETE = 'MAP_TOGGLE_COMPLETE';
export const HIDE_MAP = 'HIDE_MAP';
export const SHOW_MAP = 'SHOW_MAP';
export const SET_COMBINE_BY_FIELD = 'SET_COMBINE_BY_FIELD';
export const SET_MIN_ZOOM = 'SET_MIN_ZOOM';
export const ADD_CLUSTERS = 'ADD_CLUSTERS';
export const REMOVE_CLUSTERS = 'REMOVE_CLUSTERS';
export const SET_MAP_LAYER = 'SET_MAP_LAYER';
export const TOGGLE_DRAW_CONTROLS = 'TOGGLE_DRAW_CONTROLS';
export const ADD_SHAPE = 'ADD_SHAPE';
export const REMOVE_SHAPE = 'REMOVE_SHAPE';



/////////////////////////
// Export/Download Applets
/////////////////////////
export const EXPORT_BANK_DETAILS_DATA = 'EXPORT_BANK'
export const EXPORT_TABLE_DATA = 'EXPORT_TABLE';
export const EXPORT_TABLE_SUCCESS = 'EXPORT_TABLE_SUCCESS';
export const EXPORT_TABLE_FAILURE = 'EXPORT_TABLE_FAILURE';
export const EXPORT_TABLE_PENDING = 'EXPORT_TABLE_PENDING';
export const CLEAR_TABLE_DOWNLOAD_STATE = 'CLEAR_TABLE_DOWNLOAD_STATE';
export const DOWNLOAD_KEYLINES_IMAGE = 'DOWNLOAD_KEYLINES_IMAGE';


////////////////////////
// KEYLINES DISPLAY
////////////////////////
export const SET_FOREGROUND_KL_DATA = 'SET_FOREGROUND_KL_DATA';
export const SET_KEYLINES_CONTEXT_MENU = 'SET_KEYLINES_CONTEXT_MENU';
export const CLEAR_KEYLINES_CONTEXT_MENU = 'CLEAR_KEYLINES_CONTEXT_MENU';
export const SINGLE_OUT_NODE = 'SINGLE_OUT_NODE';
export const SET_CHART_LOADED = 'SET_CHART_LOADED';
export const SET_CHART_LAYOUT = 'SET_CHART_LAYOUT';
export const CHART_SIZE_CHANGED = 'CHART_SIZE_CHANGED';

///////////////////////
// API DATA REQUESTS
///////////////////////
export const REPLACE_DATA = 'REPLACE_DATA';
export const APPEND_DATA = 'APPEND_DATA';
export const LUCENE_SEARCH = 'LUCENE_SEARCH';
export const API_SEARCH_SUCCESSFUL = 'API_SEARCH_SUCCESSFUL';
export const API_SEARCH_FAILED = 'API_SEARCH_FAILED';
export const API_DETAILS_SUCCESSFUL = 'API_DETAILS_SUCCESSFUL';
export const API_DETAILS_FAILED = 'API_DETAILS_FAILED';
export const API_NEIGHBORS_SUCCESSFUL = 'API_NEIGHBORS_SUCCESSFUL';
export const API_NEIGHBORS_EXCEEDED = 'API_NEIGHBORS_EXCEEDED';
export const API_NEIGHBORS_FAILED = 'API_NEIGHBORS_FAILED';
export const RETRIEVE_NEIGHBOURS_BY_ID = 'RETRIEVE_NEIGHBOURS_BY_ID';
export const RETRIEVE_NEIGHBOURS_BY_ID_SUCCESS = 'RETRIEVE_NEIGHBOURS_BY_ID_SUCCESS';
export const RETRIEVE_NEIGHBOURS_BY_ID_FAILED = 'RETRIEVE_NEIGHBOURS_BY_ID_FAILED';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';
export const UPDATE_FILTERED_DATA = 'UPDATE_FILTERED_DATA';
