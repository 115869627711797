/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { inPolygon } from './inPolygon';

export const shapeNames = {
  circle: 'circle',
  rectangle: 'rectangle',
  polygon: 'polygon',
};

export const prepShapeForStore = (shapeType,shapeLayer) => {
    // convert a leaflet shape layer (single shape) into format that is stored in redux

    // newShape will be overridden with a leaflet shape instance before return
    // id will be a unique id string
    var newShape = null;
    const id = uuidv4();
  
    if (shapeType === shapeNames.circle) {
      const center = shapeLayer.getLatLng(); 
      const radius = shapeLayer.getRadius(); // radius in metres
  
      newShape = {
        type: shapeNames.circle,
        center: center,
        radius: radius,
      };
    } else if (shapeType === shapeNames.rectangle) {
      const bounds = shapeLayer.getBounds();
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest();
  
      newShape = {
        type: shapeNames.rectangle,
        sw: sw,
        ne: ne,
      };
    } else if (shapeType === shapeNames.polygon) {
      const points = shapeLayer.getLatLngs();
      newShape = {
        type: shapeNames.polygon,
        points: points,
      };
    }
    return [newShape, id]
  }


export const createShapeFromStore = (shape) => {
    // take a shape data from redux store and craete a leaflet shape instance from it

    var newShape = null;
    if (shape.type === shapeNames.circle) {
      newShape = new L.circle(
        [shape.center.lat,shape.center.lng],
        {radius: shape.radius}
      )
    } else if (shape.type === shapeNames.rectangle) {
      const sw = shape.sw;
      const ne = shape.ne;
      newShape = new L.Rectangle([
        [sw.lat,sw.lng],
        [ne.lat,ne.lng],
      ]);
    } else if (shape.type === shapeNames.polygon) {
      newShape = new L.polygon(shape.points);
    }
    return newShape;
  }

export const filterByMapShapes = (mapShapes,returnedData) => {
    var containedDataPoints = [];
    // extract nodes from the API data. This array will contain all of nodes that
    // have not yet been checked for containment. If a node is found to be within
    // an active shape, it will be removed from this array and not checked again
    var nodesToCheck = [...returnedData.items]
    var linksToCheck = [];

    // Iterate over the active shapes, then over nodes, checking each one for 
    // containment.
    Object.keys(mapShapes).forEach(shapeId => {
      var bounds = null;

      // Get current shape from redux store, transform into a leaflet shape instance
      const shape = mapShapes[shapeId];
      const leafletShape = createShapeFromStore(shape);

      // Can't get bounding box of circle without knowledge of the projection
      // type. For polygon and rectangle, bounds can be retrieved with getBounds()
      if (shape.type !== shapeNames.circle) {
        bounds = leafletShape.getBounds();
      } 

      // Iterate over remaining nodes that aren't contained and see if they are inside 
      // the current shape

      // Iterating backwards so that array items can be spliced off without breaking 
      // indexing means that as points are found, the iteration loop becomes 
      // shorter for the other shapes
      for (var index = nodesToCheck.length -1; index >= 0; index--) {
        const node = nodesToCheck[index];
        if (node.type === 'node') {
          if (node.pos.lat !== 'unknown') {
            const point = L.latLng(node.pos.lat,node.pos.lng)
            if (bounds) {
              // first check the bounding box of the polygon
              // if point not contained by bounding box, it can be rejected
              if (bounds.contains(point)) {
  
                // if bounds contain the point, and the shape is a rectangle, 
                // point must be inside shape. If shape is a polygon, further 
                // checks are required
  
                if (shape.type === shapeNames.rectangle) {
                  nodesToCheck.splice(index,1);
                  containedDataPoints.push(node);
                } else if (shape.type === shapeNames.polygon) {
                  if (inPolygon(point,shape.points[0])) {
                    nodesToCheck.splice(index,1);
                    containedDataPoints.push(node);
                  }
                }
              }
            } else {
              if (shape.type === shapeNames.circle) {
                // For circles, radius specified in metres. Check that point is within radius
                const center = shape.center;
                if (point.distanceTo(center) < shape.radius) {
                  nodesToCheck.splice(index,1);
                  containedDataPoints.push(node);
                }
              }
            }
          } else {
            nodesToCheck.splice(index,1);
          }
        } else if (node.type === 'link') {
          nodesToCheck.splice(index,1);
          linksToCheck.push(node);
        }
      }
    })

    if (containedDataPoints.length > 0) {
      // Extract the ids of nodes that are inside the shapes
      const containedIds = containedDataPoints.map(node => {return node.id});
      // Find the links that connect any nodes that are left after the filter
      // Both ends of the link should be on visible nodes
      var filteredLinks = [];
      linksToCheck.forEach(item => {
        if (containedIds.includes(item.id1)
        && containedIds.includes(item.id2)) {
          filteredLinks.push(item);
        }
      })

      return [...containedDataPoints,...filteredLinks]
    } else {
      return []
    }

  }