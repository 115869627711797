/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import NetworkLayoutMenu from './NetworkLayoutMenu';
import { Button, IconButton, Slide, Tooltip } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MapToggleButton from './MapToggleButton';
import ClusterMenu from './ClusterMenu';
import TileMenu from './TileMenu';
import DrawControlsToggle from './DrawControlsToggle';
import FullscreenButton from '../../../../../common/components/fullscreen/FullscreenButton';
import ChartDownloadButton from './ChartDownloadButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    chartControlsContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: "100%",
        zIndex: 999,
        width: theme.overrides.MuiToolbar.root.height,
    },
    controlsToggle: {
        position: 'absolute',
        right: 0,
    },
    toolbarDiv: {
        position: 'absolute',
        height: "100%",
        backgroundColor: theme.palette.background.toolbar,
    },
    hiddenToolbarDiv: {
        maxWidth: 0,
    }
}));

const ControlRail = ( { chart, chartMap, containerRef } ) => {
    const classes = useStyles();
    const [controlRailOpen, setControlRailOpen] = useState(false);
    const mapVisible = useSelector(state => state.analyticsReducer.keylinesConfig.mapVisible);

    const toggleControlRail = () => {
        setControlRailOpen(!controlRailOpen);
    };


    return (
    <div className={clsx(classes.chartControlsContainer,!controlRailOpen && classes.hiddenToolbarDiv)}>
        <Slide in={controlRailOpen} direction='left'>
            <div className={clsx(classes.toolbarDiv)}>
                <Tooltip
                PopperProps={{
                    container: containerRef.current
                }}
                title='Close Toolbar'
                color='primary'>
                    <IconButton
                    color='primary'
                    onClick={toggleControlRail}>
                        <ChevronRightIcon />
                    </IconButton>
                </Tooltip>
                <MapToggleButton
                chartMap={chartMap}
                containerRef={containerRef}/>
                {!mapVisible && 
                    <NetworkLayoutMenu
                    chart={chart}
                    containerRef={containerRef}/>
                }
                {mapVisible && 
                    <ClusterMenu containerRef={containerRef}/>
                }
                {mapVisible && 
                    <TileMenu
                    chartMap={chartMap}
                    containerRef={containerRef}/>
                }
                {mapVisible && 
                    <DrawControlsToggle
                    chartMap={chartMap}
                    containerRef={containerRef}/>
                }
                <ChartDownloadButton
                chart={chart}
                containerRef={containerRef}
                />
                <FullscreenButton
                containerRef={containerRef}
                documentBoolean={false}
                />
            </div>
        </Slide>
        <Slide in={!controlRailOpen} direction='left'>
            <Button 
            className={classes.controlsToggle}
            color='primary'
            disableElevation={true}
            onClick={toggleControlRail}
            endIcon={<ChevronLeftIcon/>}
            variant='contained'>
                Tools
            </Button>
        </Slide>
        </div>);
};
ControlRail.propTypes = {
    chart: PropTypes.any,
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    chartMap: PropTypes.any,
  }
export default ControlRail;