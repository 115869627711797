/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { HashRouter } from "react-router-dom";
import theme from './theme';
import { AuthComponent } from './common/components/auth/AuthComponent';
import './App.css';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <AuthComponent />
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
