/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { KeyLineChartOptions } from '../../../../common/config/keylinesMapConfig';


export const resetAllFontSizes = (chart) => {
    chart.setProperties({id: '.', e: KeyLineChartOptions.e,fs:KeyLineChartOptions.fs},true)
  }
  
/*
export const changeObjectProp = (
    idArray,
    nodePropertiesObject=null,
    linkPropertiesObject,
    chart
  ) => {
    const arrayOfObjectsToBeEnlarged = idArray.map(objectId => {
      const item = chart.getItem(objectId);
      if (item) {
        if (item.type === 'node'){
          return {id: objectId, ...nodePropertiesObject}
        } else if (item.type === 'link') {
          return {id: objectId, ...linkPropertiesObject}
        } else {
          return null;
        }
      } else {
        return null;
      }
      
    });
    chart.setProperties(arrayOfObjectsToBeEnlarged);
  }
*/