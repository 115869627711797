/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    infoText: {
        color: theme.palette.on.white
    },
    tagline: {
        fontSize: 24,
    },
    image: {
        align: "center",
        alignSelf:"center",
        maxWidth: 200,
        maxHeight: 200,
        padding: theme.spacing(0.5),
    }
}));
const RansackLandingInfo = () => {
    const classes = useStyles();
    return <React.Fragment>
        <Typography variant='h3' className={classes.infoText}>
            RAN$ACK
        </Typography>
        <Typography variant='h3' className={clsx(classes.infoText,classes.tagline)}>
            powered by Vigilance™
        </Typography>
        <img className={classes.image}
        src={'/illuminate.png'}
        alt="Illuminate Logo"
        id="LoginLogo"
      />
        <Typography variant='h4' className={classes.infoText}>
            ANALYTICS FOR IMPROVED FINANCIAL THREAT INTELLIGENCE 
        </Typography>
    </React.Fragment>
}

export default RansackLandingInfo;