/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import AutoCSVDownload from './AutoCSVDownload';
import PropTypes from 'prop-types';


function ExportComponent({success, data, fileName, fileType, clearFunction}) {
    //data should be an array of objects
    let headers =[];
    if (data && data.length > 0 && data[0] !== null) {
        headers = Object.keys(data[0])
    }

    return (success && <AutoCSVDownload
        data={data}
        headers={headers}
        filename={(fileName + fileType).replace(/ /g, '_')}
        clearFunction={clearFunction}
        />
        );
}
ExportComponent.propTypes = {
    success: PropTypes.bool,
    data: PropTypes.array,
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    clearFunction: PropTypes.func,
}
export default ExportComponent;