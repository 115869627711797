/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as Actions from './loginActionTypes';

export const updateLoginInfoAction = (dispatch,field,newValue) => {
    dispatch({
      type: Actions.UPDATE_LOGIN_INFO,
      field: field,
      newValue: newValue,
    })
  }
export const loginUserAction = (dispatch) => {dispatch({type: Actions.LOGIN_USER});};
export const resetLoginAction = (dispatch) => {dispatch({type: Actions.RESET_LOGIN_STATE})}

export const requestPasswordResetAction = (dispatch) => {dispatch({type: Actions.REQUEST_PASSWORD_RESET_CODE});};
export const confirmPasswordResetAction = (dispatch) => {dispatch({type: Actions.CONFIRM_PASSWORD_RESET});};

export const attemptCompleteNewPasswordAction = (dispatch) => {dispatch({type: Actions.ATTEMPT_COMPLETE_NEW_PASSWORD})};
