/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Box from "@material-ui/core/Box";
import { Paper, Button, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { 
  attemptCompleteNewPasswordAction,
  updateLoginInfoAction } from '../../state/loginActions';
import { ResetButton } from './LoginResetButton';

const useStyles = makeStyles((theme) => ({
  root: theme.overrides.StormSaverFormRoot,
  h1: {
    marginTop: 0
  },
  ResetButton: theme.overrides.ResetButton,
}));

export const ForceNewPasswordInput = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const newPass = useSelector((state) => state.loginReducer.login.newPass)
  const newPassConfirm = useSelector((state) => state.loginReducer.login.newPassConfirm)

  const handlePassChange = (event) => {
    updateLoginInfoAction(dispatch,'newPass',event.target.value);
  };
  const handlePassConfirmChange = (event) => {
    updateLoginInfoAction(dispatch,'newPassConfirm',event.target.value);
  };

  const attemptSignUp = () => {
    const error = genPassErrorString();
    updateLoginInfoAction(dispatch,'error',error);
    if (error === ''){
      attemptCompleteNewPasswordAction(dispatch);
    }
  }

  const genPassErrorString = () => {
    let errorMessage = '';
    if (String(newPass) !== String(newPassConfirm)) {
      errorMessage += 'Passwords must match; ';
    } 
    if (String(newPass).length < 8) {
      errorMessage += 'Passwords must be 8 characters or longer; ';
    }
    /*
    if (Number(passStrength) < 2) {
      errorMessage += 'Passwords must be at least "Medium"; ';
    }
    */
    return errorMessage;
  }
  

    return (
    <Paper className={classes.root}>
      <Typography variant='h4'>
        PLEASE CHANGE YOUR PASSWORD BEFORE PROCEEDING
      </Typography>
      <Box>
        <TextField
        required
        id="outlined-password-input"
        label="Password"
        type="password"
        autoComplete="current-password"
        margin="normal"
        variant="outlined"
        value={newPass}
        onChange={handlePassChange}/>
    </Box>
    <Box>
        <TextField
        required
        id="password-confirm"
        label="Confirm Password"
        type="password"
        autoComplete="current-password"
        margin="normal"
        variant="outlined"
        onChange={handlePassConfirmChange}
        value={newPassConfirm}
        />
    </Box>
      <Box>
        <Button 
        variant="contained" 
        color="primary" 
        onClick={attemptSignUp}>
          Submit
        </Button>
      </Box>
      <ResetButton buttonClass={classes.ResetButton}/>
    </Paper>
    )
}