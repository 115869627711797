/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Box from "@material-ui/core/Box";
import { Paper, Button, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { updateLoginInfoAction, confirmPasswordResetAction } from '../../state/loginActions';
import { ResetButton } from './LoginResetButton';

const useStyles = makeStyles((theme) => ({
  ResetButton: theme.overrides.ResetButton,
}));

export const PasswordResetAuthenticationForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authCode = useSelector((state) => state.loginReducer.login.authCode)
  const newPass = useSelector((state) => state.loginReducer.login.newPass)

  const handleCodeChange = (event) => {
    updateLoginInfoAction(dispatch,'authCode',event.target.value)
  };
  const handleNewPassChange = (event) => {
    updateLoginInfoAction(dispatch,'newPass',event.target.value)
  };
  const confirmPasswordReset = () => {
    confirmPasswordResetAction(dispatch);
  };

  return (
  <Paper>
    <Typography variant='h2'>
      CONFIRM
    </Typography>
    <Typography variant='h4'>
      You should have received an email containing your authentication code. 
      Input the code, and your desired new password.
    </Typography>
    <Box>
      <TextField
        required
        label="Auth Code"
        placeholder="XXXXXX"
        variant="outlined"
        margin="normal"
        onChange={handleCodeChange}
        value={authCode}/>
    </Box>
    <Box>
      <TextField
        required
        label="New Password"
        type="password"
        variant="outlined"
        margin="normal"
        onChange={handleNewPassChange}
        value={newPass}/>
    </Box>
    <Box>
      <Button 
      variant="contained" 
      color="primary" 
      onClick={confirmPasswordReset}>
        Authenticate
      </Button>
    </Box>
    <ResetButton buttonClass={classes.ResetButton}/>
  </Paper>
  )
}