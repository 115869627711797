/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { Auth } from 'aws-amplify'
import { refreshTokenAction } from "../../../state/user/userActions"

export default async function refreshToken(dispatch) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        refreshTokenAction(dispatch, session);
      });
    } catch (e) {
      console.error(e);
    }
}