/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { exportTableAction, exportBankDetailsAction ,clearDownloadStateAction, downloadKeylinesImageAction } from "../../views/AnalyticsPage/state/analyticsActions";

export const tableOptions = {
   names:['.csv','.pdf', '.txt'],
   downloadFunction: exportTableAction,
   downloadClearAction: clearDownloadStateAction
}

export const bankDetailsOptions = {
   names:['.csv', '.pdf', '.txt'],
   downloadFunction: exportBankDetailsAction,
   downloadClearAction: clearDownloadStateAction
}

export const imageDownloadOptions = { 
   names:[".png", ".pdf"],
   downloadFunction: downloadKeylinesImageAction,
   downloadClearAction: clearDownloadStateAction
}