/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppbarLogo from './AppbarLogo';
import HamburgerMenu from './UpperRightHamburger/HamburgerMenu';
import FullscreenButton from '../../../../common/components/fullscreen/FullscreenButton';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    appbarButtonsHolder: {
        position: "absolute",
        right: 0,
        display: 'flex'
    },
    userGreeting: {
      marginRight:100,
      marginLeft:'auto'
    },
    appBar: {
      position: "absolute",
      top: 0,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    greetingItem:{
      flex:1,
      float:"left"
    }
  }));

  export const RsAppBar = () => {
      const classes = useStyles();
      const user = useSelector(state => state.userReducer.user);
      return (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters={true}>
            <AppbarLogo />
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              RAN$ACK powered by Vigilance™
            </Typography>
            {user && <div className={classes.userGreeting} display="flex" >
            <PersonOutlineIcon className={classes.greetingItem}/>
            <Typography  className={classes.greetingItem} component="h1" variant="subtitle1" color="inherit" align="right">
              {"Welcome " + user.attributes.email}
            </Typography>
            </div>}
            <span className={classes.appbarButtonsHolder}>
              <FullscreenButton
              containerRef={{current: document.body}}
              documentBoolean={false}
              />
              <HamburgerMenu />
            </span>
          </Toolbar>
        </AppBar>
      )
  }