/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { IconButton, Menu, Tooltip } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayersIcon from '@material-ui/icons/Layers';
import { setMapTilesAction } from '../../../state/analyticsActions';
import { mapLayers } from '../../../../../common/config/keylinesMapConfig';
import PropTypes from 'prop-types';


const TileMenu = ( { chartMap, containerRef } ) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const tilesUrl = useSelector(state => state.analyticsReducer.keylinesConfig.tilesUrl);

    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        };
    const open = Boolean(anchorEl)

    const changeTiles = (url) => {
        if (chartMap) {
            setMapTilesAction(dispatch,url);
        }
    };

    const handleItemClick = (event,newUrl) => {
        if (newUrl !== null) {
            changeTiles(newUrl);
            // Find map name that matches the new url
            var newName = '';
            Object.entries(mapLayers).forEach((layer) => {
                if (layer[1] === newUrl) {
                    newName = layer[0];
                }
            })
            // Set map name in localstorage so tiles choice persists between refreshes
            localStorage.setItem("mapLayerName", newName);
        }
        
    };


    return <div>
        <Tooltip
        PopperProps={{
            container: containerRef.current
        }}
        title='Map Tiles'
        color='primary'>
            <IconButton onClick={handleClick}>
                <LayersIcon />
            </IconButton>
        </Tooltip>
        <Menu
        // this is important to avoid adding padding to body div
        container={containerRef.current}
        disableScrollLock={true}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={open}
        onClose={handleClose}>
            <ToggleButtonGroup
            value={tilesUrl}
            exclusive
            orientation='vertical'
            onChange={handleItemClick}
            >
                {Object.keys(mapLayers).map(layerName => {
                return <ToggleButton 
                value={mapLayers[layerName]}
                key={layerName}>
                    {layerName}
                </ToggleButton>
            })}
            </ToggleButtonGroup>
        </Menu>
    </div>
}
TileMenu.propTypes = {
    chartMap: PropTypes.any,
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }
export default TileMenu;