/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { DownloadDialog } from '../../../../../common/components/download/DownloadDialog';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { imageDownloadOptions } from '../../../../../common/config/fileTypeOptions'
import PropTypes from 'prop-types';

const ChartDownloadButton = ( { chart, containerRef } ) => {
    const [downloadOpen, setDownloadOpen] = useState(false);
    const handleDownloadOpen = () => {
        setDownloadOpen(true);
    }
    const mapVisible = useSelector(state => state.analyticsReducer.keylinesConfig.mapVisible);

    return (
    <div>
        <Tooltip
        PopperProps={{
            container: containerRef.current
        }}
        title="Download">
            <IconButton color='primary' onClick={handleDownloadOpen}>
                <PhotoCameraIcon/>
            </IconButton>
        </Tooltip>
        <DownloadDialog
        dialogOpen={downloadOpen}
        setDialogOpen={setDownloadOpen}
        fileTypeOptions={imageDownloadOptions}
        dataToExport={chart}
        targetVisualization={mapVisible ? "Map" : "Network Chart"}
        containerRef={containerRef}/>
        </div>);
};
ChartDownloadButton.propTypes = {
    chart: PropTypes.any,
    containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }

export default ChartDownloadButton;