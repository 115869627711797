/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { clearExceededResultsWarning } from '../../../views/AnalyticsPage/state/analyticsActions';
import PropTypes from 'prop-types';


export const ResultsExceededWarning = ({
    selector,
    id,
    anchorEl
}) => {
    const [showWarning, setShowWarning] = useState(false);
    const warning = useSelector(selector);
    const dispatch = useDispatch();
    
    const handleAlertClose = () => {
        setShowWarning(false)
    }

    useEffect(() => {
        setShowWarning(true)
        const timer = setTimeout(() => {
            setShowWarning(false);
            clearExceededResultsWarning(dispatch);
        }, 20000);
        return () => clearTimeout(timer);
    }, [warning, dispatch])
      
    return (
        warning &&
        <Popper
        style={{
            zIndex: 2000,
            width: "95%"}}
        id={id}
        open={showWarning}
        placement="right-end"
        anchorEl={anchorEl}>
            <Alert onClose={handleAlertClose} severity="warning">{warning}</Alert>
        </Popper>    )
}
ResultsExceededWarning.propTypes = {
    selector: PropTypes.func,
    id: PropTypes.string,
    anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}