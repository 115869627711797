/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ResizeDetector from 'react-resize-detector';
import CssBaseline from '@material-ui/core/CssBaseline';
import { RsAppBar } from './components/Appbar/Appbar';
import SideDrawer from './components/ActionDrawer/SideDrawer';
import NetworkChart from './components/NetworkChart/NetworkChart';
import BankDetailsDrawer from './components/BankDetailsDrawer/BankDetailsDrawer';
import ResultsTable from './components/ResultsTable/ResultsTable';
import { ResultsExceededWarning } from '../../common/components/feedback/ResultsExceededWarning';
import PopupMenu from './components/NetworkChart/PopupMenu';
import { chartSizeChangedAction } from './state/analyticsActions';
import { Button, Tooltip } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { toggleBankDetailsDrawerAction } from './state/analyticsActions';



const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    position: 'relative'
  },
  content: {
    overflow: "hidden",
    width: "100%",
    // set this way to avoid appbar
    top: `calc(
      ${theme.overrides.MuiToolbar.root.height}px)`,
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    backgroundSize: "100vw 100vh",
    maxHeight: `calc(
      100% 
      - ${theme.overrides.MuiToolbar.root.height}px)`},
  actionDrawerShiftedContent: {
    width: `calc(100% - ${theme.overrides.SideDrawer.width}px)`
  },
  bankDetailsShiftedContent: {
    width: `calc(100% - ${theme.overrides.BankDrawer.width}px)`
  },
  test: {
    position: "relative",
    height: "60%",
    backgroundColor: theme.palette.primary.light,
  },
  test2: {
    position: "relative",
    minHeight: 0,
    height: "40%",
    backgroundColor: theme.palette.background.surface,
  },
  networkAndTableSection: {
      position: "relative",
    },
  bankDrawerOpen: {
    minWidth: theme.overrides.BankDrawer.width,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
  },
  bankDrawerClosed: {
    width: 0,
  },
  takeFullHorizontalSpace: {
    width: "100%"
  },
  smoothWidthChanges: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  takeFullHeight: {
    height: "100%",
  },
  bankDetailsContainerToggle: {
    position: 'absolute',
    zIndex: 999,
    top: 0,
  },
}));

const AnalyticsView = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bankDetailsDrawerOpen = useSelector(state => state.analyticsReducer.selectedBankDetails.drawerOpen);
    const [open, setOpen] = React.useState(false);
    const chartSize = useSelector(state => state.analyticsReducer.chartSize);
    const [chartCoords, setChartCoords] = React.useState({});
    const chartContainerRef = React.useRef(null);
    const warningRef = React.useRef();

    const toggleBankDetailsDrawer = () => {
      toggleBankDetailsDrawerAction(dispatch)
    }

    // useEffect that gets bounding box of chart div to pass into popup menu
    // Fired by chartSize change in resize observer
    useEffect(() => {
      // UseEffect fires after render cycle, so we know that our ref has the updated position
      // Check whether state box matches the ref box, if not update state, causing re-render
      if (chartCoords.left !== chartContainerRef.current.getBoundingClientRect().left) {
        setChartCoords({
          left: chartContainerRef.current.getBoundingClientRect().left,
          top: chartContainerRef.current.getBoundingClientRect().top});
      }
    },[chartSize,chartCoords])

    return (
        <div className={classes.root} ref={warningRef}>
          <ResultsExceededWarning
            selector={state => state.analyticsReducer.neighborsExceededWarningMessage}
            id="warning"
            anchorEl={warningRef.current}
          />
          <CssBaseline />
          <RsAppBar />
          <SideDrawer open={open} setOpen={setOpen} />
          <main className={clsx(classes.content,open && classes.actionDrawerShiftedContent)}>
            <div
            className={clsx(
            bankDetailsDrawerOpen && classes.bankDrawerOpen,
            classes.bankDrawerClosed)}>
              <BankDetailsDrawer />
            </div>
            <div
            className={clsx(
              classes.networkAndTableSection,
              bankDetailsDrawerOpen 
                ? classes.bankDetailsShiftedContent
                : classes.takeFullHorizontalSpace)}>
              <div className={classes.bankDetailsContainerToggle}>
                <Tooltip
                PopperProps={{
                    container: chartContainerRef.current
                }}
                placement='right'
                disablerestorefocus="true"
                disabletriggerfocus="true"
                disableFocusListener={true}
                title={bankDetailsDrawerOpen
                ? 'Close Bank Details Drawer'
                : 'Open Bank Details Drawer'}>
                    <Button
                    variant='contained' 
                    color='primary'
                    startIcon={<AccountBalanceIcon/>}
                    onClick={toggleBankDetailsDrawer}>
                        {bankDetailsDrawerOpen
                        ? <ChevronLeftIcon/>
                        : <ChevronRightIcon/>}
                    </Button>
                </Tooltip>
            </div>
              <div
              className={classes.test}
              ref={chartContainerRef}
              onContextMenu={(e) => e.preventDefault()}
              >
                <ResizeDetector
                  handleWidth
                  onResize={(width,height) => {
                    if (width !== chartSize.width || height !== chartSize.height) {
                      chartSizeChangedAction(dispatch,width,height);
                    }
                  }}
                  refreshMode="debounce"
                  refreshRate={500}
                  refreshOptions={{ leading: false, trailing: true }}
                />
                  {/* Keylines will go here */}
                <NetworkChart
                containerStyling={classes.takeFullHeight}
                containerRef={chartContainerRef}
                />
                <PopupMenu
                  containerRef={chartContainerRef}
                  chartBounds={chartCoords} />
              </div>
              <div className={classes.test2}>
                  {/* Table will go here! */}
                  <ResultsTable />
              </div>
            </div>
          </main>
        </div>
      );
}

export default AnalyticsView;