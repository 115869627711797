/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { databaseMapping } from './databaseFieldsMappings';

export const bankDetailsGroups = {
    locationGroup: {
        name: 'Location',
        icon: LocationOnIcon },
    bankingInfoGroup: {
        name: 'Banking Info',
        icon: AccountBalanceIcon},
    boardMembersGroup: {
        name: 'Board Members',
        icon: PeopleAltIcon},
    threatProfileGroup: {
        name: 'Threat Profile',
        icon: NewReleasesIcon},
}
export const keysArray = [
    bankDetailsGroups.locationGroup,
    bankDetailsGroups.bankingInfoGroup,
    bankDetailsGroups.boardMembersGroup,
    bankDetailsGroups.threatProfileGroup];

export const subgroups = {
    sanctionGroup: 'Sanctions',
    FATFGroup: 'FATF',
    additionalInfoGroup: 'Additional',
}

export const defaultBankDetails = {
    [databaseMapping['Institution Name'].dbName]: {
        name: databaseMapping['Institution Name'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['Physical Address 1'].dbName]: {
        name: databaseMapping['Physical Address 1'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['Physical Address 2'].dbName]: {
        name: databaseMapping['Physical Address 2'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['City'].dbName]: {
        name: databaseMapping['City'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['State/Province'].dbName]: {
        name: databaseMapping['State/Province'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['Postal Code'].dbName]: {
        name: databaseMapping['Postal Code'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['Country'].dbName]: {
        name: databaseMapping['Country'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['Contact Info'].dbName]: {
        name: databaseMapping['Contact Info'].displayName,
        value: null,
        group: bankDetailsGroups.locationGroup.name,
    },
    [databaseMapping['SWIFT/BIC'].dbName]: {
        name: databaseMapping['SWIFT/BIC'].displayName,
        value: null,
        group: bankDetailsGroups.bankingInfoGroup.name,
    },
    [databaseMapping['Officer Names'].dbName]: {
        name: databaseMapping['Officer Names'].displayName,
        value: [],
        group: bankDetailsGroups.boardMembersGroup.name,
    },
    [databaseMapping['EU Sanctions'].dbName]: {
        name: databaseMapping['EU Sanctions'].displayName,
        value: null,
        group: bankDetailsGroups.threatProfileGroup.name,
        subgroup: subgroups.sanctionGroup,
    },
    [databaseMapping['HMT Sanctions'].dbName]: {
        name: databaseMapping['HMT Sanctions'].displayName,
        value: null,
        group: bankDetailsGroups.threatProfileGroup.name,
        subgroup: subgroups.sanctionGroup,
    },
    [databaseMapping['UN Sanctions'].dbName]: {
        name: databaseMapping['UN Sanctions'].displayName,
        value: null,
        group: bankDetailsGroups.threatProfileGroup.name,
        subgroup: subgroups.sanctionGroup,
    },
    [databaseMapping['OFAC Sanctions'].dbName]: {
        name: databaseMapping['OFAC Sanctions'].displayName,
        value: null,
        group: bankDetailsGroups.threatProfileGroup.name,
        subgroup: subgroups.sanctionGroup,
    },
    [databaseMapping['OFAC Sanctions Additional'].dbName]: {
        name: databaseMapping['OFAC Sanctions Additional'].displayName,
        value: {},
        group: bankDetailsGroups.threatProfileGroup.name,
        subgroup: subgroups.additionalInfoGroup,
    },
    [databaseMapping['FATF Rating'].dbName]: {
        name: databaseMapping['FATF Rating'].displayName,
        value: {},
        group: bankDetailsGroups.threatProfileGroup.name,
        subgroup: subgroups.additionalInfoGroup,
    }
};