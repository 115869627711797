/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

export default function inBounds(lat,lng,northEast,southWest) {
    const lngIn = (lng - northEast.lng) * (lng - southWest.lng) < 0;
    const latIn = (lat - northEast.lat) * (lat - southWest.lat) < 0;
    return lngIn && latIn;
  }