/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import { put, all, takeLatest, select, call } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import * as Actions from './loginActionTypes';

export const getLoginState = (state) => state.loginReducer.login;
export const getUser = (state) => state.userReducer.user;

function loginCall(email,pass) {
  return Auth.signIn(email,pass);
  //return true;
}

function passwordResetCall(email) {
  return Auth.forgotPassword(email);
  //return true;
}
function confirmPasswordResetCall(email,authCode,newPass) {
  return Auth.forgotPasswordSubmit(email,authCode,newPass)
  //return true;
}

function completeNewPasswordCall(user,newPass,email) {
  //return true;
  return Auth.completeNewPassword(user,newPass,{email: email});
}



export function* loginUserSaga() {
  yield takeLatest(Actions.LOGIN_USER,loginUser)
}

function* loginUser() {
  yield put({type: Actions.LOGIN_USER_IN_PROGRESS})
  const loginAttributes = yield select(getLoginState)
  try {
      const response = yield call(loginCall,loginAttributes.email,loginAttributes.pass)
      if (response) {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          yield put({type: Actions.NEW_PASSWORD_REQUIRED, user: response})
        } else {
          yield put({ type: Actions.LOGIN_USER_SUCCESS, user: response})
        }
      } else {
        yield put({ type:Actions.LOGIN_USER_FAILED, error:'An error occurred whilst communicating with Cognito'})
      }
      
  }
  catch (error) {
      console.log('error occurred in saga: ',error)
      yield put({ type:Actions.LOGIN_USER_FAILED, error:error.message})
  }
}


export function* requestPasswordResetSaga() {
  yield takeLatest(Actions.REQUEST_PASSWORD_RESET_CODE,requestPasswordReset)
}

function* requestPasswordReset() {
  yield put({type: Actions.LOGIN_USER_IN_PROGRESS})
  const loginAttributes = yield select(getLoginState)
  try {
      const response = yield call(passwordResetCall,loginAttributes.email)
      if (response) {
        yield put({ type: Actions.REQUEST_PASSWORD_RESET_CODE_SUCCESS})
      }
  }
  catch (error) {
      console.log('error occurred in saga: ',error)
      yield put({ type: Actions.REQUEST_PASSWORD_RESET_CODE_FAILED, error:error.message})
  }
}


export function* confirmPasswordResetSaga() {
  yield takeLatest(Actions.CONFIRM_PASSWORD_RESET,confirmPasswordReset)
}

function* confirmPasswordReset() {
  yield put({type: Actions.LOGIN_USER_IN_PROGRESS})
  const loginAttributes = yield select(getLoginState)
  try {
      yield call(
        confirmPasswordResetCall,
        loginAttributes.email,
        loginAttributes.authCode,
        loginAttributes.newPass)
      yield put({ type: Actions.CONFIRM_PASSWORD_RESET_SUCCESS})

  }
  catch (error) {
      console.log('error occurred in saga: ',error)
      yield put({ type: Actions.CONFIRM_PASSWORD_RESET_FAILED, error:error.message})
  }
}


export function* newPasswordRequiredSaga() {
  yield takeLatest(Actions.ATTEMPT_COMPLETE_NEW_PASSWORD,completeNewPasswordFunction)
} 
function* completeNewPasswordFunction() {
  yield put({type: Actions.LOGIN_USER_IN_PROGRESS})
  try {
    const user = yield select(getUser);
    const loginInfo = yield select(getLoginState);
    const email = loginInfo.email;
    const newPass = loginInfo.newPass;
    const response = yield call(completeNewPasswordCall,user,newPass,email)
    if (response) {
      yield put({type: Actions.FORCE_CHANGE_PASSWORD_SUCCESS})
    }
  }
  catch (error) {
    yield put({type: Actions.FORCE_CHANGE_PASSWORD_FAILED, error:error.message})
  }
}


export default function* loginSagas() {
    yield all([
      loginUserSaga(),
      requestPasswordResetSaga(),
      confirmPasswordResetSaga(),
      newPasswordRequiredSaga(),
    ]);
  }