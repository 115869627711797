/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import L from 'leaflet';
import 'leaflet-draw';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// set default leaflet marker icon
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [11,40],
});

export const SelectedIcon = L.icon({
  iconUrl: '/selectedmarker.png',
  shadowUrl: iconShadow,
  iconSize: [27, 40],
  iconAnchor: [11,40],
});

L.Marker.prototype.options.icon = DefaultIcon;

const corner1 = L.latLng(-85, -180)
const corner2 = L.latLng(85, 180)
export const mapBounds = L.latLngBounds(corner1, corner2)

export const layerNames = {
  light: "Light",
  satellite: "Satellite",
  dark: "Dark",
}

export const mapLayers = {
  [layerNames.light]: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
  [layerNames.dark]: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
  [layerNames.satellite]: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
}

export const LeafletSettings = {
  maxZoom: 17,
  minZoom: 2,
  center: mapBounds.getCenter(),
  animate: true,
  preferCanvas: false,
  transition: 'layout',
  maxBounds: mapBounds,
  maxBoundsViscosity: 1.0,
}

export const KeyLineChartOptions = {
  overview: false,
  //backgroundAlpha: 0.0,
  arrows: 'small',
  controlColour: '#54666d',
  handMode: true,
  navigation: { shown: false },
  e: 0.75,
  fs: 24,
  selectedNode: {
    //e: 1.2,
    ha0: {
      c: '#f58120',
      r: 35,
      w: 15,
    },
  },
  selectedLink: {},
  linkEnds: { avoidLabels: true, spacing: 'tight' },
  minZoom: 0.02,
}


// Initialise the FeatureGroup to store editable layers
export var editableLayers = new L.FeatureGroup();

// Initialise the draw controls, mostly disabled, extras can be added as features expand
// keys with false applied are the disabled control entries
export var drawPluginOptions = {
  position: 'bottomright',
  draw: {
    polyline: false,
    polygon: {
      allowIntersection: false, // Restricts shapes to simple polygons
      shapeOptions: {
        clickable: false
      },
      drawError: {
        color: '#e1e100', // Color the shape will turn when intersects
        message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
      },
    },
    circle: {
      shapeOptions: {
        clickable: false
      }
    },
    rectangle: {
      shapeOptions: {
        clickable: false
      }
    },
    marker: false,
    circlemarker: false,
  },
  edit: false,
};

// Initialise the draw control and pass it the FeatureGroup of editable layers
export var drawControl = new L.Control.Draw(drawPluginOptions);