/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Paper, Tooltip, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { keysArray } from '../../../../common/config/defaultBankDetails';
import BankDetailsTable from './BankDetailsTable';
import BankDetailsNav from './BankDetailsNav';
import { DownloadDialog } from '../../../../common/components/download/DownloadDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import {bankDetailsOptions} from '../../../../common/config/fileTypeOptions'

const useStyles = makeStyles((theme) => ({
    stepButtons: {
        width: theme.spacing(15),
    },
    title: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    bankDetailsContainer: {
        width: '100%',
        height: "100%",
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1)
    },
    tableHead: {
        color: theme.palette.on.white87,
        backgroundColor: theme.palette.default,
        display: 'flex',
        justifyContent: 'space-between',
      }
}))

const BankDetailsDrawer = () => {
    const [downloadOpen, setDownloadOpen] = React.useState(false);
    const handleDownloadOpen = () => {
        setDownloadOpen(true);
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const dataSelector = useSelector(
        state => state.analyticsReducer.tableCSVdownload.data);
    const successSelector = useSelector(
        state => state.analyticsReducer.tableCSVdownload.success);
    const currentKey = keysArray[activeStep];
    return (
        <Paper className={classes.bankDetailsContainer} id='detailsContainer'>
            <BankDetailsNav
            keysArray={keysArray}
            activeStep={activeStep}
            setActiveStep={setActiveStep} />
            <div className={classes.tableHead}>
                <Typography
                className={clsx(classes.title)}
                variant='h5'>
                    {currentKey.name}
                </Typography>
                <div >
                    <Tooltip title="Export" placement="right">
                        <IconButton color='inherit' onClick={handleDownloadOpen}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                    <DownloadDialog
                    dialogOpen={downloadOpen}
                    setDialogOpen={setDownloadOpen}
                    fileTypeOptions={bankDetailsOptions}
                    targetVisualization={"Bank Details"}
                    dataToExport={dataSelector}
                    successSelector={successSelector}
                    extras={activeStep}/>
                </div>
            </div>
            <BankDetailsTable
            activeStep={activeStep}
            keysArray={keysArray} />
        </Paper>
      );
}
export default BankDetailsDrawer;