/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from "react";


export default function useFullscreenStatus(elRef) {
  /*
  Custom hook that detects full screen status of the
  element provided as props and handles fullscreening/unfullscreening
  */
  const [isFullscreen, setIsFullscreen] = React.useState(
    document[getBrowserFullscreenElementProp()] != null
  );

  const setFullscreen = () => {
    /*
    Function that maximises the element provided by props.
    */
    // If ref not instantiated, do nothing
    if (elRef.current == null) return;

    // If there is an element fullscreened already, exit fullscreen
    // and then request fullscreen for the provided element
    if (document[getBrowserFullscreenElementProp()] != null) {
      document.exitFullscreen()
      .then(() => {
        elRef.current
          .requestFullscreen()
          .then(() => {
            setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
          })
          .catch(() => {
            setIsFullscreen(false);
          });
      })
    } else {
      elRef.current
        .requestFullscreen()
          .then(() => {
            setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
          })
          .catch(() => {
            setIsFullscreen(false);
          });
    }
  };

  // Exit fullscreen and update hook state that gets reported back to component
  const exitFullscreen = () => {
    document.exitFullscreen()
    .then(() => {
      setIsFullscreen(false);
    })
    .catch(() => {
      setIsFullscreen(false)});
  }

  // Layout effect that attaches a fullscreenChange listener to the element. This
  // is what will update component state when the element has been fullscreened.
  React.useLayoutEffect(() => {
    if (elRef.current != null) {
      const currentEl = elRef.current;
      currentEl.onfullscreenchange = () =>
        {setIsFullscreen(document[getBrowserFullscreenElementProp()] != null)};
      return () => (currentEl.onfullscreenchange = undefined);
    }
    return () => (document.onfullscreenchange = undefined);
  });
  return [isFullscreen, setFullscreen, exitFullscreen];
}

function getBrowserFullscreenElementProp() {
  /*
  Checks a bunch of possible names for a fullscreen element across
  browsers. Can then be used to retrieve the element by indexing document.
  */
  if (typeof document.fullscreenElement !== "undefined") {
    return "fullscreenElement";
  } else if (typeof document.mozFullScreenElement !== "undefined") {
    return "mozFullScreenElement";
  } else if (typeof document.msFullscreenElement !== "undefined") {
    return "msFullscreenElement";
  } else if (typeof document.webkitFullscreenElement !== "undefined") {
    return "webkitFullscreenElement";
  } else {
    throw new Error("fullscreenElement is not supported by this browser");
  }
}