/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import { resetAllFontSizes } from './propertySetting';
import { setKeylinesForegroundData } from '../../../../views/AnalyticsPage/state/analyticsActions';
////////////////////
// CHART SELECTIONS
////////////////////

export const selectIdAndForegroundNeighbours = (
    id,
    chart,
    dispatch,
    data,
  ) => {
    if (id) {
      const neighboursAndCentre = getNeighboursAndCentre(id,chart);
      // foregrounding doesn't work for combos like this!
      chart.selection(neighboursAndCentre);
      chart.zoom('selection',{animate: true, time: 300});
      const foregroundedDataArray = bringArrayToForeground(
        neighboursAndCentre,
        false,
        chart);
      //resetAllFontSizes(chart);
      //changeObjectProp(neighboursAndCentre,{e:1},{fs:30},chart);
      setKeylinesForegroundData(dispatch,
        {type: 'LinkChart',
        items: foregroundedDataArray});
      chart.selection(id)
    } else {
      bringArrayToForeground([],true, chart);
      setKeylinesForegroundData(dispatch,data);
      resetAllFontSizes(chart);
      chart.selection([]);
    }
  }
  
export const getNeighboursAndCentre = (id,chart) => {
    const neighbourNodes = chart.graph().neighbours(id, {hops: 1}).nodes;
    const neighbourLinks = chart.graph().neighbours(id, {hops: 1}).links;
    // array of node ids
    const neighboursAndCentre = [...neighbourNodes,...neighbourLinks,id];
    return neighboursAndCentre;
  };
  
export const bringArrayToForeground = (idArray,empty=false,chart) => {
    let foregroundedDataArray = [];
    chart.foreground(node => {
      if (empty) {
        return true;
      } else {
        if (idArray.includes(node.id)) {
          foregroundedDataArray = [...foregroundedDataArray,node];
          return true
        }
      }
      });
    return foregroundedDataArray;
  };