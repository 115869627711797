/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Dialog,
DialogActions,
DialogContent,
DialogTitle,
Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const ReferenceDialog = ({
  dialogOpen,
  setDialogOpen,
}) => {

      const handleDialogClose = () => {
        setDialogOpen(false)
      }

    return (
      
        <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        style={{textAlign:"center"}}
        overlayStyle={{backgroundColor: 'white'}}>
            <DialogTitle>
              <Typography variant="h4">
                RAN$ACK Reference
              </Typography>
            </DialogTitle>
            <DialogContent style={{minHeight:"40vh"}}>
              <Typography variant="h6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/>
              </Typography>
              <Typography>
                <br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                variant='contained' 
                onClick={handleDialogClose} 
                color="primary" 
                autoFocus>
                Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
ReferenceDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
}