/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as Actions from './loginActionTypes';
import * as userActions from '../../../common/state/user/userActionTypes';

const initialState = {
    login: {
        email: '',
        pass: '',
        updating: false,
        error: '',
        authCode: '',
        resetEmailSubmitted: false,
        passResetRequested: false,
        newPass: '',
        newPassConfirm: '',
        newPasswordRequired: false,
        loginSuccessful: false,
        }
  };

export default function loginReducer(state = initialState, action) {

switch (action.type) {
    case Actions.UPDATE_LOGIN_INFO:
    return {
        ...state,
        login: {
        ...state.login,
        [action.field]: action.newValue,
        }
    }
    case Actions.LOGIN_USER_IN_PROGRESS:
    return {
        ...state,
        login: {
        ...state.login,
        updating: true
        }
    }
    case Actions.LOGIN_USER_SUCCESS:
    return {
        ...state,
        login: {
            ...initialState.login,
            loginSuccessful: true},
    }
    case Actions.LOGIN_USER_FAILED:
    return {
        ...state,
        login: {
        ...state.login,
        updating: false,
        error: action.error,
        }
    }
    case Actions.REQUEST_PASSWORD_RESET_CODE_SUCCESS:
    return {
        ...state,
        login: {
        ...state.login,
        updating: false,
        resetEmailSubmitted: true,
        error: initialState.login.error,
        }
    }
    case Actions.REQUEST_PASSWORD_RESET_CODE_FAILED:
    return {
        ...state,
        login: {
        ...state.login,
        updating: false,
        error: action.error
        }
    }
    case Actions.CONFIRM_PASSWORD_RESET_SUCCESS:
    return {
        ...state,
        login: {
        ...state.login,
        updating:false,
        error: initialState.login.error,
        resetEmailSubmitted: false,
        passResetRequested: false,
        }
    }
    case Actions.CONFIRM_PASSWORD_RESET_FAILED:
    return {
        ...state,
        login: {
        ...state.login,
        updating: false,
        error: action.error,
            }
        }
    case Actions.NEW_PASSWORD_REQUIRED:
        return {
            ...state,
            login: {
            ...state.login,
            updating: false,
            newPasswordRequired: true,
            }
        }
    case Actions.FORCE_CHANGE_PASSWORD_FAILED:
        return {
            ...state,
            login: {
                ...state.login,
                updating: false,
                error: action.error,
            }
        }
    case Actions.FORCE_CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            login: {
            ...state.login,
            updating: false,
            newPasswordRequired: false,
            }
        }
    case userActions.CHECK_USER_SUCCEEDED:
        return {
            ...state,
            login: {
                ...state.login,
                loginSuccessful: true,
            }
        }
    case Actions.RESET_LOGIN_STATE:
        return initialState
    default:
    return state;
}
}