/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Dialog,
DialogActions,
DialogContent,
DialogTitle,
Button,
Link
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const HelpDialog = ({
  dialogOpen,
  setDialogOpen,
}) => {

      const handleDialogClose = () => {
        setDialogOpen(false)
      }

    return (
        <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        style={{textAlign:"center"}}
        overlayStyle={{backgroundColor: 'white'}}>
            <DialogTitle>
              <Typography variant="h4">
                RAN$ACK Help
              </Typography>
            </DialogTitle>
            <DialogContent style={{minHeight:"40vh"}}>
              <Typography>
                Thank you for selecting RAN$ACK. If you need assistance, please reach out to our team for support or suggestions.<br/>
                <br/>
                <Link href="mailto:ransacksupport@oneilluminate.com">ransacksupport@oneilluminate.com</Link>
                <br/>
                <br/>OneIlluminate
                <br/>Illuminate Mission Solutions
                <br/>198 Van Buren Street, Suite 200
                <br/>Herndon, VA 20170
                <br/>United States of America
                <br/>
                <br/><Link href="https://oneilluminate.com">oneilluminate.com</Link>
                <br/>Copyright © 2021 Illuminate. All Rights Reserved
              </Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                variant='contained' 
                onClick={handleDialogClose} 
                color="primary" 
                autoFocus>
                OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
HelpDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
}