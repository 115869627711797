/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

export const UPDATE_LOGIN_INFO = 'UPDATE_LOGIN_INFO';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_IN_PROGRESS = 'LOGIN_USER_IN_PROGRESS';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REQUEST_PASSWORD_RESET_CODE = 'REQUEST_PASSWORD_RESET_CODE';
export const REQUEST_PASSWORD_RESET_CODE_SUCCESS = 'REQUEST_PASSWORD_RESET_CODE_SUCCESS';
export const REQUEST_PASSWORD_RESET_CODE_FAILED = 'REQUEST_PASSWORD_RESET_CODE_FAILED';
export const CONFIRM_PASSWORD_RESET = 'CONFIRM_PASSWORD_RESET';
export const CONFIRM_PASSWORD_RESET_SUCCESS = 'CONFIRM_PASSWORD_RESET_SUCCESS';
export const CONFIRM_PASSWORD_RESET_FAILED = 'CONFIRM_PASSWORD_RESET_FAILED';

export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const ATTEMPT_COMPLETE_NEW_PASSWORD = 'ATTEMPT_COMPLETE_NEW_PASSWORD';
export const FORCE_CHANGE_PASSWORD_SUCCESS = 'FORCE_CHANGE_PASSWORD_SUCCESS';
export const FORCE_CHANGE_PASSWORD_FAILED = 'FORCE_CHANGE_PASSWORD_FAILED';

export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';