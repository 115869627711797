/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import * as Actions from './analyticsActionTypes';
import * as userActions from '../../../common/state/user/userActionTypes';
import { data as keylinesTestData} from '../../../common/data/keylinesFormatTestData';
import * as groups from '../../../common/config/defaultBankDetails';
import combineFields from '../../../common/config/combineFields';
import { layerNames, mapLayers } from '../../../common/config/keylinesMapConfig';
import layouts from '../../../common/config/chartLayouts';

const initialState = {
    selectedBankDetails: {
        bankId: null,
        drawerContentsLoading: false,
        drawerOpen: false,
        details: {...groups.defaultBankDetails},
    },
    keylinesConfig: {
        mapVisible: true,
        combineBy: combineFields.proximity,
        layout: layouts.standard,
        minZoom: 1,
        comboIds: [],
        contextMenu: {
            coords: {
                x: null,
                y: null,
            },
            menuType: null,
            id: null,
        },
        tooltip: {
            coords: {
                x: null,
                y: null,
            },
            id: null,
        },
        mapShapes: {},
        drawControlsOn: false,
        tilesUrl: 
            localStorage.getItem("mapLayerName") !== null
                ? mapLayers[localStorage.getItem("mapLayerName")] 
                    ? mapLayers[localStorage.getItem("mapLayerName")] 
                    : mapLayers[layerNames.light]
                : mapLayers[layerNames.light],
        chartLoaded: false,
    },
    chartSize: {
        width: 0,
        height: 0,
    },
    // would like two seperate instances, one of the institutions returned
    // by our database query, and one to store the current graph nodes and links
    returnedData: keylinesTestData,
    // This will store the nodes and links rendered by keylines
    keylinesData: keylinesTestData,
    keylinesForegroundData: keylinesTestData,
    nodesIndexArray: [],
    search: {
        error: '',
        value: '',
        filter: ''
    },
    detailsErrorMessage: '',
    neighborsErrorMessage: '',
    neighborsExceededWarningMessage: '',
    tableCSVdownload: {
        pending: false,
        error: '',
        data:[],
        fileType: '',
        fileName: '',
        success: false,
    },
};

export default function analyticsReducer(state=initialState,action) {
    switch (action.type) {
        case Actions.TOGGLE_BANK_DETAILS_DRAWER: {
            return {
                ...state,
                selectedBankDetails: {
                    ...state.selectedBankDetails,
                    drawerOpen: !state.selectedBankDetails.drawerOpen,
                }
            }
        }
        case Actions.SET_SELECTED_BANK_ID: {
            return {
                ...state,
                selectedBankDetails: {
                    ...state.selectedBankDetails,
                    bankId: action.bankId,
                    drawerContentsLoading: true,
                }
            }
        }
        case Actions.SET_SELECTED_BANK_DETAILS: {
            return {
                ...state,
                selectedBankDetails: {
                    ...state.selectedBankDetails,
                    drawerContentsLoading: false,
                    details: {...action.bankDetails},
                }
            }
        }
        case Actions.CLEAR_SELECTED_BANK_DETAILS: {
            return {
                ...state,
                selectedBankDetails: {
                    ...initialState.selectedBankDetails,
                    drawerOpen: state.selectedBankDetails.drawerOpen}
            }
        }
        case Actions.MAP_TOGGLE_COMPLETE: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    mapVisible: action.payload,
                }
            }
        }
        case Actions.SET_COMBINE_BY_FIELD: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    combineBy: action.combineField,
                }
            }
        }
        case Actions.SET_MIN_ZOOM: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    minZoom: action.zoom,
                }
            }
        }
        case Actions.SET_FOREGROUND_KL_DATA: {
            return {
                ...state,
                keylinesForegroundData: {...action.data}
            }
        }
        case Actions.SET_MAP_LAYER: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    tilesUrl: action.url,
                }
            }
        }
        case Actions.SET_CHART_LOADED: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    chartLoaded: action.payload,
                }
            }
        }
        case Actions.ADD_SHAPE: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    mapShapes: {
                        ...state.keylinesConfig.mapShapes,
                        [action.shapeId] : {...action.shape}
                    }
                }
            }
        }
        case Actions.REMOVE_SHAPE: {
            const shapes = state.keylinesConfig.mapShapes;
            // return the shapes array with the id's corresponding entry removed
            let copy = Object.assign({},shapes);
            delete copy[action.shapeId];

            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    mapShapes: {
                        ...copy,
                    }
                }
            }
        }
        case Actions.TOGGLE_DRAW_CONTROLS: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    drawControlsOn: !state.keylinesConfig.drawControlsOn
                }
            }
        }
        case Actions.SET_CHART_LAYOUT: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    layout: action.payload,
                }
            }
        }
        case Actions.UPDATE_FILTERED_DATA: {
            return {
                ...state,
                keylinesData: {
                    type: action.data.type,
                    items: [...action.data.items]
                }
            }
        }
        case Actions.CHART_SIZE_CHANGED: {
            return {
                ...state,
                chartSize: {
                    width: action.width,
                    height: action.height,
                }
            }
        }
        case Actions.SET_KEYLINES_CONTEXT_MENU: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    contextMenu: {
                        ...state.keylinesConfig.contextMenu,
                        coords: {
                            x: action.x,
                            y: action.y,
                        },
                        menuType: action.menuType,
                        id: action.id,
                        title: action.title,
                    }
                }
            }
        }
        case Actions.CLEAR_KEYLINES_CONTEXT_MENU: {
            return {
                ...state,
                keylinesConfig: {
                    ...state.keylinesConfig,
                    contextMenu: {
                        ...initialState.keylinesConfig.contextMenu,
                    }
                }
            }
        }
        case Actions.SINGLE_OUT_NODE: {
            const currentData = state.keylinesData;
            const items = currentData.items;

            // named new
            const arrayContainingItemAtId = items
            .filter(item => item.id === action.id)

            // If the node being singled out is the currently selected one
            // retain selection state, otherwise reset it to prevent chart searching for 
            // non-existent ids
            let match=false;
            if (action.id === state.selectedBankDetails.bankId) {
                match = true;
            }
            return {
                ...state,
                selectedBankDetails: match 
                ? {...state.selectedBankDetails}
                : {...initialState.selectedBankDetails,
                    drawerOpen: state.selectedBankDetails.drawerOpen
                },
                returnedData: {
                    ...state.returnedData,
                    items: [...arrayContainingItemAtId],
                },
                nodesIndexArray: [action.id]
            }
        }
        case Actions.REPLACE_DATA: {
            return {
                ...state,
                returnedData: {...action.payload},
                nodesIndexArray: [...action.nodesIndexArray]
            }
        }
        case Actions.APPEND_DATA: {
            return {
                ...state,
                returnedData: {
                    ...state.returnedData,
                    items: [
                        ...state.returnedData.items,
                        ...action.payload
                    ],
                },
                nodesIndexArray: [
                    ...state.nodesIndexArray,
                    ...action.nodesIndexArray,
                ],
            }
        }
        case Actions.UPDATE_SEARCH_TERM: {
            return {
                ...state,
                search: {
                    ...state.search,
                    value: action.searchTerm,
                },
            }
        }
        case Actions.UPDATE_SEARCH_FILTER: {
            return {
                ...state,
                search: {
                    ...state.search,
                    filter: action.filterTerm,
                },
            }
        }
        case Actions.API_SEARCH_SUCCESSFUL: {
            return {
                ...state,
                search: {
                    ...initialState.search,
                }
            }
        }
        case Actions.API_SEARCH_FAILED: {
            return {
                ...state,
                search: {
                    ...state.search,
                    error: action.error,
                }
            }
        }
        case Actions.API_DETAILS_SUCCESSFUL: {
            return {
                ...state,
                detailsErrorMessage: initialState.detailsErrorMessage
            }
        }
        case Actions.API_DETAILS_FAILED: {
            return {
                ...state,
                detailsErrorMessage: action.error
            }
        }
        case Actions.API_NEIGHBORS_SUCCESSFUL: {
            return {
                ...state,
                neighborsErrorMessage: initialState.neighborsErrorMessage
            }
        }
        case Actions.API_NEIGHBORS_FAILED: {
            return {
                ...state,
                neighborsErrorMessage: action.error
            }
        }
        case Actions.API_NEIGHBORS_EXCEEDED: {
            return {
                ...state,
                neighborsExceededWarningMessage: action.warning
            }
        }
        case Actions.EXPORT_TABLE_FAILURE: {
            return {
                ...state,
                    tableCSVdownload: {
                        ...state.tableCSVdownload,
                        pending: false,
                        success: false,
                        error: action.error,
                    }
            }
        }
        case Actions.EXPORT_TABLE_PENDING: {
            return {
                ...state,
                    tableCSVdownload: {
                        ...state.tableCSVdownload,
                        pending: true,
                        fileType: action.fileType,
                        fileName: action.fileName,
                    }
            }
        }
        case Actions.EXPORT_TABLE_SUCCESS: {
            return {
                ...state,
                    tableCSVdownload: {
                        ...state.tableCSVdownload,
                        pending: false,
                        success: true,
                        data: action.data,
                        error: initialState.tableCSVdownload.error,
                    }
            }
        }
        case Actions.CLEAR_TABLE_DOWNLOAD_STATE: {
            return {
                ...state,
                    tableCSVdownload: { ...initialState.tableCSVdownload}
            }
        }
        case userActions.USER_SIGN_OUT:
            return {...initialState};
        default:
            return state;
        

    }
}