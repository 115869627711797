/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { DrawerContents } from './DrawerContents';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: theme.overrides.SideDrawer.width,
    background: theme.palette.background.navDrawer,
    color: theme.palette.text.navDrawer,
    borderColor: theme.palette.secondary.main,
    top: `calc(
      ${theme.overrides.MuiToolbar.root.height}px
      + ${theme.overrides.ClassificationBanner.height}px)`,
    height: `calc(
      100vh 
      - ${theme.overrides.MuiToolbar.root.height}px
      - ${theme.overrides.ClassificationBanner.height}px
      - ${theme.overrides.ClassificationBanner.height}px)`,
  },
  drawerContainer: {
    overflow: "hidden"
  },
  drawerOpen: {
    width: theme.overrides.SideDrawer.width,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(8)
  },
}));

const SideDrawer = ({ open, setOpen }) => {
    const classes = useStyles();
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };
    return (
          <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}>
            <div className={classes.drawerContainer}>
                <DrawerContents handleOpen={handleDrawerOpen} expanded={open} />
                <IconButton 
            style={{position: "absolute", bottom: "0"}}
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? <MenuOpenIcon  /> : <MenuIcon />}
            </IconButton>
            </div>
        </Drawer>
      );
}
SideDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default SideDrawer;