/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/




import { createMuiTheme } from "@material-ui/core/styles";
import * as colors from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    default: "rgb(10,42,59)",
    primary: {
      main: "rgb(10,42,59)",
      main87: "rgb(10,42,59,0.87)",
      main70: "rgb(10,42,59,0.70)",
      main60: "rgb(10,42,59,0.60)",
      main38: "rgb(10,42,59,0.38)",
      main25: "rgb(10,42,59,0.25)",
      main12: "rgb(10,42,59,0.12)",
      main05: "rgb(10,42,59,0.05)",
      light: "rgb(55,82,101)",
      light87: "rgb(55,82,101, 0.87)",
      light70: "rgb(55,82,101, 0.70)",
      light38: "rgb(55,82,101, 0.38)",
      light30: "rgb(55,82,101, 0.30)",
      light25: "rgb(55,82,101, 0.25)",
      light12: "rgb(55,82,101, 0.12)",
      light05: "rgb(55,82,101, 0.05)",
      dark: "rgb(0,0,22)",
      dark87: "rgb(0,0,22, 0.87)",
      dark70: "rgb(0,0,22, 0.70)",
      dark38: "rgb(0,0,22, 0.38)",
      dark25: "rgb(0,0,22, 0.25)",
      dark12: "rgb(0,0,22, 0.12)",
      dark05: "rgb(0,0,22, 0.05)"
    },
    secondary: {
      main: "rgb(144,164,174)",
      main87: "rgb(144,164,174, 0.87)",
      main70: "rgb(144,164,174, 0.70)",
      main56: "rgb(144,164,174, 0.56)",
      main38: "rgb(144,164,174, 0.38)",
      main30: "rgb(144,164,174, 0.30)",
      main25: "rgb(144,164,174, 0.25)",
      main12: "rgb(144,164,174, 0.12)",
      main05: "rgb(144,164,174, 0.05)",
      light: "rgb(193,213,224)",
      light87: "rgb(193,213,224,0.87)",
      light70: "rgb(193,213,224,0.70)",
      light56: "rgb(193,213,224,0.56)",
      light38: "rgb(193,213,224,0.38)",
      light30: "rgb(193,213,224,0.30)",
      light25: "rgb(193,213,224,0.25)",
      light12: "rgb(193,213,224,0.12)",
      light05: "rgb(193,213,224,0.05)",
      dark: "rgb(98,117,127)",
      dark87: "rgb(98,117,127, 0.87)",
      dark70: "rgb(98,117,127, 0.70)",
      dark56: "rgb(98,117,127, 0.56)",
      dark38: "rgb(98,117,127, 0.38)",
      dark30: "rgb(98,117,127, 0.30)",
      dark25: "rgb(98,117,127, 0.25)",
      dark12: "rgb(98,117,127, 0.12)",
      dark05: "rgb(98,117,127, 0.05)"
    },
    background: {
      default: "#f5f6f8",
      surface: "#fcfdff",
      navDrawer: "#c1d5e0",
      cardHeader: "#e5e5e5",
      toolbar: "rgb(98,117,127)",
      error: "#F6E0E0",
      success: "#E5EFE5",
      warning: "#FBF4E1",
      info: "#E9F1F4"
    },
    semantic: {
      error: "#BB0000",
      warning: "#E2A508",
      success: "#2B7D2B",
      info: "#4a8da9"
    },
    on: {
      black:   "rgb(0,0,0,1.00)",
      black87: "rgb(0,0,0,0.87)",
      black70: "rgb(0,0,0,0.70)",
      black56: "rgb(0,0,0,0.56)",
      black38: "rgb(0,0,0,0.38)",
      black25: "rgb(0,0,0,0.25)",
      black15: "rgb(0,0,0,0.15)",
      black12: "rgb(0,0,0,0.12)",
      black05: "rgb(0,0,0,0.05)",
      black02: "rgb(0,0,0,0.02)",
      white:   "rgb(255,255,255,1.00)",
      white87: "rgb(255,255,255,0.87)",
      white70: "rgb(255,255,255,0.70)",
      white56: "rgb(255,255,255,0.56)",
      white38: "rgb(255,255,255,0.38)",
      white30: "rgb(255,255,255,0.30)",
      white25: "rgb(255,255,255,0.25)",
      white20: "rgb(255,255,255,0.20)",
      white12: "rgb(255,255,255,0.12)",
      white05: "rgb(255,255,255,0.05)",
    },
    action: {
      default: "rgb(10,42,59)",
      primary: "rgb(98,117,127)"
    },
    text: {
      primary: "#000000",
      secondary: "#0a2a3b",
      navDrawer: "#375265",
    },
    menu: {
      text: "rgba(0, 0, 0, 0.70)",
      icon: "rgba(0, 0, 0, 0.56)",
    },
    pagination: {
      enabled: "rgb(0, 0, 0, 0.7)",
      disabled: "rgb(0, 0, 0, 0.3)",
      hover: "rgb(0, 0, 0, 0.87)",
      focus: "rgb(0, 0, 0)",
      pressed: "rgb(0, 0, 0)",
      active: "rgb(0, 0, 0)",
    },
    paginationBg: {
      enabled: "rgb(0, 0, 0, 0.0)",
      disabled: "rgb(0, 0, 0, 0.0)",
      hover: "rgb(0, 0, 0, 0.05)",
      focus: "rgb(0, 0, 0, 0.12)",
      pressed: "rgb(0, 0, 0, 0.25)",
    },
    toolbar: {
      enabled: "rgb(255, 255, 255, 0.7)",
      disabled: "rgb(255, 255, 255, 0.3)",
      hover: "rgb(255, 255, 255, 0.87)",
      focus: "rgb(255, 255, 255)",
      pressed: "rgb(255, 255, 255)",
      active: "rgb(255, 255, 255)",
    },
    toolbarBg: {
      enabled: "rgb(255, 255, 255, 0.0)",
      disabled: "rgb(255, 255, 255, 0.0)",
      hover: "rgb(255, 255, 255, 0.05)",
      focus: "rgb(255, 255, 255, 0.12)",
      pressed: "rgb(255, 255, 255, 0.25)",
    },
    visualizationColors: {
      axis: {
        line: colors.common.black,
        tick: colors.common.black,
        label: colors.common.black,
      },
      brushFillProps:{
        fill: "rgb(255,255,255,0.38)",
        stroke: "rgb(0,0,0,0.25)",
      },
      legend: {
        label: colors.common.black,
        outline: colors.common.black,
      },
      primary: [
        colors.amber[500],
        colors.indigo[500],
        colors.lightBlue[500],
        colors.lightGreen[500],
        colors.red[500],
        colors.teal[500],
        colors.yellow[500],
        colors.lime[500],
        colors.deepPurple[500],
        colors.brown[500],
        colors.amber[900],
        colors.indigo[900],
        colors.lightBlue[900],
        colors.lightGreen[900],
        colors.red[900],
        colors.teal[900],
        colors.yellow[900],
        colors.lime[900],
        colors.deepPurple[900],
        colors.brown[900],
        colors.amber[200],
        colors.indigo[200],
        colors.lightBlue[200],
        colors.lightGreen[200],
        colors.red[200],
        colors.teal[200],
        colors.yellow[200],
        colors.lime[200],
        colors.deepPurple[200],
        colors.brown[200],
      ],
    }
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Roboto Medium"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Helvetica",
      "Arial",
      "sans-serif",
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: -1.5
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      letterSpacing: -0.5
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      letterSpacing: 0
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      letterSpacing: 0.25
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: 0
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.1
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.5
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.25
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 1.1
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.4
    },
    overline: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: 1.5
    },
  },
});

theme.overrides = {

  MuiOutlinedInput: {
    root:{
      ...theme.typography.body1,
      backgroundColor: theme.palette.on.white38,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.light30,
        borderWidth: 1
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
        }
      },
      "&:hover":{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main60
        },
      },
      "&.Mui-disabled, &:hover.Mui-disabled": {
        backgroundColor: "transparent",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.light25
        }
      },
      "&.Mui-error, &:hover&.Mui-error, &.Mui-focused.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.semantic.error
        }
      }
    }
  },

  MuiInputLabel: {
    outlined: {
      "&.MuiInputLabel-marginDense": {
        transform: "translate(14px, 7px) scale(1)",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
      },
      "&.MuiInputLabel-shrink": {
        transform: "translate(12px, -9px) scale(0.75)"
      },
      "&.Mui-error": {
        color: theme.palette.semantic.error,
      }
    }
  },

  MuiFormLabel: {
    root: {
      color: '#0a2a3b99',
      backgroundColor: 'transparent',
      padding: '4px',
      lineHeight: '1'
    }
  },

  MuiPaper: {
    root: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default
    },
    rounded: {
      borderRadius: 2
    }
  },
  MuiCardHeader: {
    root: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.primary,
      padding: "8px 12px",
      height: 36,
      maxHeight: 36
    }
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.primary.light87
    },
    colorSecondary: {
      "&.Mui-checked": {
        color: theme.palette.primary.light87
      }
    }
  },
  MuiDivider: {
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0
    },
    vertical: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
  },
  MuiListItem: {
    button: {
      minHeight: 40,
      height: 40,
    },
    root: {
      color: theme.palette.menu.text,
      ...theme.typography.body2
    },
    secondaryAction: {
      paddingRight: "64px"
    }
  },
  MuiListItemText:{
    primary:{
      ...theme.typography.body2
    }
  },
  MuiListItemIcon: {
    root: {
      minWidth: 34,
      color: theme.palette.menu.icon,
      "& .MuiSvgIcon-root": {
        height: 18,
        width: 18
      }
    }
  },
  MuiListSubheader: {
    root: {
      ...theme.typography.caption,
      height: theme.spacing(4),
      userSelect: "none",
      cursor: "default",
      display: "flex",
      alignItems: "center"

    }
  },
  MuiMenu: {
    paper: {
      backgroundColor: theme.palette.background.surface
    },
    list: {
      "& .MuiCheckbox-root": {
        "& .MuiTouchRipple-root": {
          display: "none"
        }
      }
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      letterSpacing: theme.typography.body2.letterSpacing,
      height: 40
    },
    gutters: {
      paddingRight: 19,
    }
  },
  MuiTabs: {
    root: {
      borderBottom: "1px solid rgba(0,0,0,0.12)",
      backgroundColor: theme.palette.primary.light,
      height: 72,
      color: 'white',
    },
    indicator: {
      backgroundColor: theme.palette.secondary.light
    },
  },
  MuiTable: {
    root: {
      backgroundColor: theme.palette.background.surface,
    }
  },
  MuiTableHead: {
    root: {
      backgroundColor: theme.palette.default,
      padding: "8px 12px",
      // maxHeight: theme.spacing(6.5),
      // minHeight: theme.spacing(6.5),
      "& .MuiTableCell-root": {
        color: theme.palette.on.white87,
        backgroundColor: theme.palette.default,
        ...theme.typography.body1
      }
    },
  },
  MuiTableRow: {
    root: {
      height: theme.spacing(6),
      "&:hover": {
        backgroundColor: theme.palette.secondary.light30  + " !important",
      }
    },
    head: {
      height: theme.spacing(6.5),
    },
    footer: {
      position: "absolute",
      bottom: 0,
      width: "100%"
    }
  },
  MuiTableCell: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    head: {
      backgroundColor: theme.palette.background.surface,
      color: theme.palette.text.primary
    },
    body: {
      color: theme.palette.text.secondary
    }
  },
  MuiTablePagination: {
    selectRoot: {
      "& .MuiSelect-select": {
        marginRight: 16,
        color: theme.palette.primary.main,
        minHeight: 0,
        lineHeight: "20px"
      },
      "& .MuiSelect-icon": {
        right: 16,
        color: theme.palette.primary.main87
      }
    },
    root: {
      backgroundColor: theme.palette.background.surface,
      color: theme.palette.pagination.enabled,
      borderTop: "1px solid",
      borderColor: theme.palette.on.black15,
      position: "sticky",
      bottom: 0,
      "& .MuiTypography-root": {
        paddingLeft: 16,
        paddingRight: 16
      },
      "& .MuiIconButton-root": {
        color: theme.palette.pagination.enabled,
        "&.Mui-disabled": {
          color: theme.palette.pagination.disabled,
        },
        "& .MuiSvgIcon-root, .MuiIconButton-label": {
          width: 24,
          height: 24
        },
        "& .MuiTouchRipple-root": {
          top: 2,
          bottom: 2,
          left: 2,
          right: 2,
          width: 44,
          height: 44
        },
        "&:hover": {
          color: theme.palette.pagination.hover,
          backgroundColor: "transparent",
          "& .MuiTouchRipple-root": {
            backgroundColor: theme.palette.paginationBg.hover,
            opacity: 1
          }
        },
        "& .Mui-focusVisible": {
          color: theme.palette.pagination.focus,
          "& .MuiTouchRipple-root": {
            backgroundColor: theme.palette.paginationBg.focus,
            opacity: 1
          }
        },
        "&:pressed, &:hover:focus:pressed": {
          color: theme.palette.pagination.pressed,
          "& .MuiTouchRipple-root": {
            backgroundColor: theme.palette.paginationBg.pressed,
            opacity: 1
          }
        },
      },
    },
    toolbar: {
      minHeight: 48
    }
  },
  MuiIconButton: {
    root: {
      color: theme.palette.primary.main,
      "&.toggle": {
        padding: 11,
        border: "1px solid",
        borderRadius: 2,
        borderColor: theme.palette.on.black12,
        "&.active": {
          backgroundColor: theme.palette.primary.main25,
        },
        "&.inactive": {
          borderColor: theme.palette.on.black12,
        },
      },
      "& .MuiSvgIcon-root, .MuiIconButton-label": {
        width: 24,
        height: 24
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& .MuiTouchRipple-root": {
          backgroundColor: theme.palette.primary.main05,
          opacity: 1
        }
      },
      "& .Mui-focusVisible": {
        "& .MuiTouchRipple-root": {
          backgroundColor: theme.palette.primary.main12,
          opacity: 1
        }
      },
      "&:pressed, &:hover:focus:pressed": {
        "& .MuiTouchRipple-root": {
          backgroundColor: "red",
          opacity: 1
        }
      },
    }
  },

  MuiAppBar: {
    root: {
      "&.secondary-toolbar": {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.toolbar.enabled,
        "& .MuiToolbar-gutters": {
          paddingLeft: 8,
          paddingRight: 8,
        },

        "& .analytics-link": {
          color: theme.palette.toolbar.enabled,
          borderRadius: 24,
          "&[disabled]": {
            color: theme.palette.toolbar.disabled,
            pointerEvents: "none"
          },
          "& .MuiSvgIcon-root": {
            width: 24,
            height: 24
          },
          "&:hover": {
            color: theme.palette.toolbar.hover,
            backgroundColor: theme.palette.toolbarBg.hover,
          },
          "&:focus": {
            color: theme.palette.toolbar.focus,
            backgroundColor: theme.palette.toolbarBg.focus,
          },
          "&:pressed, &:hover:focus:pressed": {
            color: theme.palette.toolbar.pressed,
            backgroundColor: theme.palette.toolbarBg.pressed,
          },
        },

        "& a.active-analytics-link": {
          color: theme.palette.toolbar.active,
          "&:focus": {
            backgroundColor: "transparent"
          }
        },

        "& .MuiTypography-root, .MuiSelect-iconFilled": {
          paddingLeft: 8,
          paddingRight: 8,
          color: theme.palette.toolbar.enabled,
          cursor: "default",
          pointerEvents: "none",
          "& *": {
            pointerEvents: "none"
          }
        },


        "& .MuiSlider-track": {
          backgroundColor: theme.palette.on.white56
        },
        "& .MuiSlider-rail": {
          backgroundColor: theme.palette.primary.dark
        },
        "& .MuiSlider-thumb": {
          backgroundColor: "#C3CBD1",
          "&.Mui-disabled": {
            backgroundColor: '#8394A0'
          },
          "&:hover, &:focus" : {
            backgroundColor: "#E6EAEC",
            boxShadow: "0px 0px 0px 8px rgba(255, 255, 255, 0.15)"
          }
        },
        "& .MuiSlider-root.Mui-disabled": {
          "& .MuiSlider-rail": {
            backgroundColor: theme.palette.primary.dark38
          },
          "& .MuiSlider-track": {
            backgroundColor: theme.palette.on.white12
          }
        },
      },

    }
  },
  MuiToolbar: {
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      minHeight: "48px !important",
      maxHeight: "48px !important",
      height: 48,
      color: theme.palette.toolbar.enabled,
      "& .MuiIconButton-root, .MuiInputBase-root": {
        color: theme.palette.toolbar.enabled
      },
      "& .MuiDivider-root": {
        backgroundColor: theme.palette.toolbar.disabled
      },
      "& label": {
        fontWeight: "bold",
        paddingRight: "0.25em",
        marginTop: "0.33em"
      },
      "& .MuiInputBase-root": {
        "&:hover": {
          backgroundColor: theme.palette.on.white05
        },
        "&:focus": {
          backgroundColor: theme.palette.on.white12
        }
      },
      "& .MuiFormControl-root": {
        "& .MuiInputBase-root": {
          color: theme.palette.on.white70,
          borderRadius: 0,
          // paddingRight: 2,
          "&.Mui-focused": {
            backgroundColor: theme.palette.on.white12,
            "& input": {
              color: theme.palette.on.white,
              "&[value='']": {
                color: theme.palette.secondary.light
              }
            },
          },
          "&:hover": {
            backgroundColor: theme.palette.on.white5,
            "& input": {
              color: theme.palette.on.white87,
              "&[value='']": {
                color: theme.palette.secondary.light87
              }
            },
          },
          "& input": {
            height: 48,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 8,
            color: theme.palette.on.white70,
            ...theme.typography.body2,
            "&[value='']": {
              color: theme.palette.secondary.light70,
              height: 48,
              paddingBottom: 0,
              borderBottom: 0
            }
          },
          "& .MuiInputAdornment-root": {
            "& .Mui-disabled": {
              opacity: 0
            }
          }
        }
      },

      "& .MuiOutlinedInput-root": {
        ...theme.typography.body1,
        backgroundColor: "transparent",
        color: theme.palette.secondary.light38,
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused": {
          backgroundColor: theme.palette.on.white12
        },
        "&:hover":{
          backgroundColor: theme.palette.on.white05
        },
        "&.Mui-disabled": {
          backgroundColor: "transparent",
          color: theme.palette.on.white38
        }
      },
      "& .toolbar-toggle": {
        "& .MuiIconButton-root": {
          color: theme.palette.toolbar.active
        }
      },
      "& .toolbar-toggle-inactive": {
        "& .MuiIconButton-root": {
          color: theme.palette.toolbar.enabled
        }
      },
      "& .MuiIconButton-root": {
        color: theme.palette.toolbar.enabled,
        "&.Mui-disabled": {
          color: theme.palette.toolbar.disabled,
        },
        "& .MuiSvgIcon-root, .MuiIconButton-label": {
          width: 24,
          height: 24
        },
        "& .MuiTouchRipple-root": {
          top: 2,
          bottom: 2,
          left: 2,
          right: 2,
          width: 44,
          height: 44
        },
        "&:hover": {
          color: theme.palette.toolbar.hover,
          backgroundColor: "transparent",
          "& .MuiTouchRipple-root": {
            backgroundColor: theme.palette.toolbarBg.hover,
            opacity: 1
          }
        },
        "& .Mui-focusVisible": {
          color: theme.palette.toolbar.focus,
          "& .MuiTouchRipple-root": {
            backgroundColor: theme.palette.toolbarBg.focus,
            opacity: 1
          }
        },
        "&:pressed, &:hover:focus:pressed": {
          color: theme.palette.toolbar.pressed,
          "& .MuiTouchRipple-root": {
            backgroundColor: theme.palette.toolbarBg.pressed,
            opacity: 1
          }
        },
      },

      "& .MuiButtonBase-root.MuiIconButton-root.MuiSwitch-switchBase": {
        height: "38px",
        color: theme.palette.secondary.main,
        padding: "9px",
        '&.Mui-checked': {
          color: theme.palette.secondary.light,
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.secondary.dark,
          opacity: "0.5"
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: theme.palette.secondary.main,
          opacity: "1"
        },
        '&.Mui-disabled': {
          color: "#738693",
        },
        '&.Mui-disabled.Mui-checked': {
          color: "#738693",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: "#738693",
          opacity: "0.3"
        },
        '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
          backgroundColor: "#738693",
          opacity: "0.5"
        },
        "& .MuiTouchRipple-root": {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 38,
          height: 38
        },
      },
    }
  },

  MuiButton : {
    root: {
      padding: "0px 16px",
      height: "36px",
      borderRadius: "2px",
      color: theme.palette.primary.main
    },
    contained: {
      backgroundColor: theme.palette.background.surface,
      color: theme.palette.primary.main,
      "&$disabled": {
        color: theme.palette.primary.main38,
        border: "1px solid " + theme.palette.primary.light25,
        backgroundColor: "transparent",
        boxShadow: "none"
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main05,
      }
    },
    textPrimary: {
      color: theme.palette.secondary.dark
    }
  },

  MuiSelect: {
    root: {
      fontSize: 14,
    },
    selectMenu:{
      height: "20px"
    },
    select:{
      "&:focus": {
      backgroundColor: theme.palette.on.white25,
      }
    },
    filled: {
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: 4,
      padding: "0.8em",
      "&:focus": {
        backgroundColor: theme.palette.on.white12,
        borderRadius: 4,
      }
    },
    iconFilled: {
      color: theme.palette.text.primary
    }
  },

  MuiTooltip: {
    tooltip: {
      backgroundColor: theme.palette.primary.dark87,
      color: theme.palette.on.white87,
      borderRadius: 4,
      height: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...theme.typography.caption
    }
  },

  MuiChip: {
    root: {
      backgroundColor: theme.palette.secondary.light56,
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
      "& .MuiTouchRipple-root": {
        "& .MuiTouchRipple-rippleVisible": {
          "& .MuiTouchRipple-child": {
            backgroundColor: theme.palette.secondary.main,
          }
        },
      },
    },
    clickable: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.secondary.light,
      }
    },
    deletable: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.secondary.light,
      }
    },
    label: {
      ...theme.typography.body2,
      color: theme.palette.on.black87,
      "& .MuiIcon-root": {
        color: theme.palette.on.black70
      }
    },
    deleteIcon: {
      color: theme.palette.on.black56
    }
  },

  MuiSlider: {
    thumb: {
      backgroundColor: theme.palette.primary.light,
      "&:hover, &:focus": {
        boxShadow: "0px 0px 0px 8px " + theme.palette.primary.light25
      },
      "&.Mui-disabled": {
        backgroundColor: '#969798'
      },
    },
    track: {
      backgroundColor: theme.palette.primary.light87
    },
    rail: {
      backgroundColor: theme.palette.primary.light38
    }
  },

  MuiDialog: {
    paper: {
      borderRadius: "4px",
      minWidth: "30vw",
      maxWidth: "80vw"
    }
  },

  MuiDialogTitle: {
    root: {
      ...theme.typography.h6,
      color: theme.palette.on.black87
    }
  },

  MuiDialogContent: {
    root: {
      ...theme.typography.body1,
      color: theme.palette.on.black87,
      paddingBottom: "28px"
    }
  },

  MuiFormHelperText: {
    root: {
      color: theme.palette.on.black56,
      "&.Mui-error": {
        color: theme.palette.semantic.error,
      }
    }
  },

  MuiLegendStyling: {
    paper: {
      color: theme.palette.visualizationColors.legend.label,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.visualizationColors.legend.outline}`,
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
    }
  },

  ClassificationBanner: {
    height: 24,
  },
  SideDrawer: {
    width: 240,
  },
  BankDrawer: {
    width: 480,
  },
  LoginFormRoot: {
    minWidth: 360,
    maxWidth: 360,
    height: "100%",
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    '& .MuiPaper-root':{
      opacity: 0.9,
      position: "relative",
      width: '90%',
      height: "100%",
      padding: theme.spacing(2),
      textAlign: "center",
      justifyContent: 'center',
      alignItems: 'center'},
    '& .MuiTextField-root': {
      width: "80%",
    }
  },
  ResetButton: {
    position: "absolute",
    left: "0px",
    bottom: "0px"
  },

};

export default theme;
