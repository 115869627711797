/* Copyright (C) Illuminate Operations Inc., 2021.
 * All Rights Reserved.
 * 
 * Project: Ransack 
 * Author: Vigilance 
 * Release Date: 2021 
 * Version: 0.0.1 
*/


import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { PasswordResetEmailEntryForm } from './PasswordResetEmailEntryForm';
import { PasswordResetAuthenticationForm } from './PasswordResetAuthenticationForm';
import LoginForm from './LoginForm';
import { ForceNewPasswordInput } from './ForceNewPasswordInput';
import { ErrorAlert } from '../../../../common/components/feedback/ErrorAlert';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  error: {
    position: 'absolute',
    maxHeight: "10%",
  }
}));

export default function LoginPage({path}) {
  const classes = useStyles();

  const awaitingPasswordReset = useSelector((state) => state.loginReducer.login.passResetRequested)
  const resetEmailSubmitted = useSelector((state) => state.loginReducer.login.resetEmailSubmitted)
  const newPasswordRequired = useSelector((state) => state.loginReducer.login.newPasswordRequired)
  const isLoading = useSelector((state) => state.loginReducer.login.updating)

  const chooseLoginStep = () => {
    if (isLoading) {
      return null
      // return <Loading />
    } else {
      if (awaitingPasswordReset) {
        if (resetEmailSubmitted) {
          return <PasswordResetAuthenticationForm />
        } else {
          return <PasswordResetEmailEntryForm />
        }
      } else if (newPasswordRequired){
        return <ForceNewPasswordInput />
      } else {
        return <LoginForm path={path}/>
      }
    }

  }

  return <React.Fragment>
      {chooseLoginStep()}
      <ErrorAlert
      style={classes.error}
      selector={(state) => state.loginReducer.login.error} />
    </React.Fragment>
}
LoginPage.propTypes = {
  path: PropTypes.string,
}